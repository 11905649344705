<template>
  <div>
    <el-table
      ref="table"
      v-loading="tableLoading"
      :data="tableData"
      :expand-row-keys="expands"
      :header-cell-style="{ background: '#fafafa' }"
      :row-key="getRowKeys"
      class="mb-3"
      max-height="500px"
      style="text-align: center"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="expand">
        <template #header>
          <div class="el-table__expand-icon" @click="handleExpandAll">
            <i :class="expandArrowIcon" />
          </div>
        </template>
        <template slot-scope="scope">
          <el-row :span="24" class="expand-row border" style="background-color: #f7f7f7" type="flex">
            <el-col :span="2">{{ $t('title.Shippingwarehouse') }}</el-col>
            <el-col :span="4">SKU</el-col>
            <el-col :span="4">{{ $t('title.PlatformSku') }}</el-col>
            <!-- <el-col :span="1" style="min-width: 140px">ItemID</el-col> -->
            <el-col :span="2" class="specification">{{
              $t('title.Specification')
            }}
            </el-col>
            <el-col style="width: 60px">{{
              $t('title.aQuantity')
            }}
            </el-col>
            <!-- 分配数 -->
            <el-col style="width: 60px">{{
              $t('title.distributionsum')
            }}
            </el-col>
            <el-col :span="1" style="width: 60px">{{
              $t('title.shipmentsnum')
            }}
            </el-col>
            <el-col :span="1">{{ $t('title.Amount') }}</el-col>
            <el-col :span="2">{{ $t('title.Deliverytime') }}</el-col>
            <el-col :span="2" style="min-width: 110px">{{
              $t('title.wmsOrderNumber')
            }}
            </el-col>
            <el-col :span="3" style="text-align: center">{{
              $t('title.shipmentnumber')
            }}
            </el-col>
            <el-col :span="1">{{ $t('page.status') }}</el-col>
            <!-- bu -->
            <el-col :span="2">BU-{{ $t('tips.operation') }}</el-col>
            <el-col :span="2">{{ $t('page.operate') }}</el-col>
          </el-row>
          <!-- 换成唯一key -->
          <el-row
            v-for="(item, index) in scope.row.itemSplitList"
            :key="index"
            :span="24"
            :style="{
              height:
                item.status === 6 &&
                platform.toLocaleUpperCase() == 'EBAY' &&
                scope.row.status === 8
                  ? '140px'
                  : '90px',
              backgroundColor: '#f7f7f7',
            }"
            class="expand-row border"
            type="flex"
          >
            <el-col :span="2">{{
              (item.warehouseName === undefined || item.warehouseName === '') ? item.warehouse : item.warehouseName
            }}
            </el-col>
            <el-col :span="4" style="margin-right: 10px">
              <span style="width:168px;height:100%;word-wrap: break-word" @dblclick="copy(item.sku)">{{
                item.sku
              }}</span>
              <!-- <el-popover placement="top-start" trigger="hover" :content="item.sku"><span
                slot="reference"
                @dblclick="copy(item.sku)"
              >{{
                item.sku | ellipsis
              }}</span></el-popover> -->
            </el-col>
            <el-col :span="4">
              <el-popover :content="item.platSku" placement="top-start" trigger="hover"><span
                slot="reference"
                @dblclick="copy(item.platSku)"
              >{{
                item.platSku | ellipsis
              }}</span></el-popover>
            </el-col>
            <!-- <el-col :span="1" style="min-width: 140px"><span @dblclick="copy(item.originalItemId)">{{
              item.originalItemId
            }}</span></el-col> -->
            <el-col :span="2" class="specification"><span>{{ item.style }}<br>{{ item.color }}<br>{{
              item.sizes
            }}</span></el-col>
            <!-- 数量 -->
            <el-col style="width: 60px">{{
              item.itemQuantity
            }}
            </el-col>
            <!-- 分配数 -->
            <template
              v-if="
                item.status === 2 ||
                  item.status === 3 ||
                  item.status === 4 ||
                  item.status === 5 ||
                  item.status === 99 ||
                  item.status === 301
              "
            >
              <el-col style="width: 60px">{{
                item.itemQuantity
              }}
              </el-col>
            </template>
            <template v-else>
              <el-col style="width: 60px" />
            </template>
            <!-- 发货数 -->
            <el-col style="width: 60px">{{
              item.sendQuantity
            }}
            </el-col>
            <el-col :span="1">{{ item.itemTotal }}</el-col>
            <el-col :span="2">{{ item.sendDate | dataFormat }}</el-col>
            <el-col :span="2" style="min-width: 110px">
              <el-popover :content="item.wmsOrderNumber" placement="top-start" trigger="hover"><span
                slot="reference"
                @dblclick="copy(item.wmsOrderNumber)"
              >{{
                item.wmsOrderNumber
              }}</span></el-popover>
            </el-col>
            <el-col :span="3" style="text-align: center">
              <el-popover
                :content="(item.carrierCode? '['+item.carrierCode+']' :'')+item.trackingNumber"
                placement="top-start"
                trigger="hover"
              >
                <div slot="reference">
                  <div v-if="item.trackingNumber">
                    <div v-for="(item, index) in item.trackingNumber.split(',')" :key="index">
                      <a :href="'https://t.17track.net/zh-cn#nums=' + item" style="color: #3296fa" target="_blank">
                        {{ item | ellipsis }}
                      </a>
                    </div>
                  </div>
                  <div v-else />
                </div>
              </el-popover>
            </el-col>
            <!-- 明细状态 -->
            <el-col :span="1">
              <template>
                <span v-if="item.status == 0">{{
                  $t('title.Staypointswarehouse')
                }}</span>
                <span v-else-if="item.status == 1">{{
                  $t('title.Pointsinthewarehouse')
                }}</span>
                <span v-else-if="item.status == 2">{{
                  $t('title.Havepointswarehouse')
                }}</span>
                <span v-else-if="item.status == 3">{{
                  $t('title.Partialshipment')
                }}</span>
                <span v-else-if="item.status == 4">{{
                  $t('title.Shippe')
                }}</span>
                <span v-else-if="item.status == 5">{{
                  $t('title.waitingship')
                }}</span>
                <span v-else-if="item.status == 6">{{
                  $t('title.lessship')
                }}</span>
                <span v-else-if="item.status == 7">{{
                  $t('title.ProductMismatch')
                }}</span>
                <span v-else-if="item.status == 8">{{
                  $t('title.InsufficientInventory')
                }}</span>
                <span v-else-if="item.status == 88">{{
                  $t('title.normal')
                }}</span>
                <span v-else-if="item.status == 99">{{
                  $t('title.Cancelleds')
                }}</span>
                <span v-else-if="item.status == 10">{{
                  $t('title.cannotgetoutwarehouse')
                }}</span>
                <span
                  v-else-if="item.status == 301"
                  style="color: rgb(30, 159, 255); cursor: pointer"
                  @click="toRma(scope.row)"
                >{{ $t('title.Aftersalesservice') }}</span>
                <span v-else-if="item.status == 9">{{
                  $t('title.Inventorytostaytuned')
                }}</span>
              </template>
            </el-col>
            <!-- bu -->
            <el-col :span="2">
              <template>
                <span>{{
                  (item.bu === undefined ? '' : item.bu) + '-' + (item.operationUser === undefined ? '' : item.operationUser)
                }}</span>
              </template>
            </el-col>
            <!-- 手动分配仓库(操作) -->
            <el-col :span="2" class="operate" style="tetx-align: center">
              <template>
                <template>
                  <!-- 普通的分仓 -->
                  <el-button
                    v-if="(item.status === 4 && item.warehouseType === 'WARE_HOUSE_WYT') || item.status === 8 && scope.row.status === 8 || item.status === 9 && scope.row.status === 3"
                    :loading="loading"
                    type="text"
                    @click="distributionWarehouses(scope.row, item, 0)"
                  >{{ $t('title.allocateSure') }}
                  </el-button>
                  <!-- 分仓按钮(调用库存待调) -->
                  <el-button
                    v-else-if="item.status === 9 && scope.row.status === 8 || item.status === 9 && scope.row.status === 6"
                    v-loading.fullscreen.lock="loading"
                    element-loading-background="rgba(0, 0, 0, 0.8)"
                    type="text"
                    @click="
                      distributionWarehousesTobeadjusted(scope.row, item, 0)
                    "
                  >{{ $t('title.allocateSure') }}
                  </el-button>
                  <!-- 修正SKU -->
                  <el-button
                    v-if="item.status === 9 && scope.row.status === 8"
                    type="text"
                    @click="handleskuDialog(item, scope.row)"
                  >{{ $t('tips.CorrectedSKU') }}
                  </el-button>
                  <el-button
                    v-else-if="
                      !item.rmaProcessOrder &&
                        (item.status === 10 ||
                        (item.status === 6 && scope.row.status === 8) ||
                        (item.status === 6 && scope.row.status === 97)||
                        (item.status === 6 && scope.row.status === 10) ||
                        (item.status === 6 && scope.row.status === 3) ||
                        (item.status === 10 && scope.row.status === 3) ||
                        (item.status === 6 && scope.row.status === 6))
                    "
                    :loading="tunedLoading"
                    type="text"
                    @click="_changeToAdjustedStock(item)"
                  >
                    {{ $t('title.toTuned') }}
                  </el-button>
                </template>
                <template>
                  <!-- <el-button
                    v-if="
                      (item.platform.toLocaleUpperCase() == 'EBAY' || item.platform.toLocaleUpperCase() == 'STANDWEBSITE') &&
                        item.status === 6 &&
                        scope.row.status === 8
                    "
                    :loading="fbaloading"
                    type="text"
                    @click="distributionWarehouses(scope.row, item, 2)"
                  >
                    {{ $t("title.TransfertoFBA") }}</el-button> -->
                </template>
                <template>
                  <!-- 创建RMA -->
                  <el-button
                    v-if="(scope.row.platform === 'AMAZON'||
                      scope.row.platform === 'WALMART'||
                      scope.row.platform === 'EBAY') &&
                      ( scope.row.status === 0 ||
                      scope.row.status === 1 ||
                      scope.row.status === 7 ||
                      scope.row.status === 8 ||
                      scope.row.status === 6 ||
                      scope.row.status === 97 ||
                      scope.row.status === 10 ||
                      scope.row.status === 3)
                    "
                    type="text"
                    @click="createRMA(item)"
                  >{{ $t('title.createRMA') }}
                  </el-button>
                  <el-button type="text" @click="editNotice(item)">{{
                    $t('title.note')
                  }}
                  </el-button>
                </template>
                <template>
                  <!--  1. 平台：shein，非shein平台订单不显示 -->
                  <!--  2. 订单的商品行明细状态为status“无法出库10、缺货6”状态时，就可操作  -->
                  <el-button v-if="item.platform && item.platform.toLocaleUpperCase() ==='SHEIN' && (item.status === 6 || item.status === 10)" type="text" size="medium" @click="handleBeOutofStock(scope.row,item)">缺货</el-button>
                </template>
              </template>
            </el-col>
          </el-row>
          <el-row
            v-for="item in scope.row.itemSplitList"
            :key="item.id"
            :span="24"
            style="
              font-size: 14px !important;
              background-color: #f7f7f7 !important;
            "
          >
            <span v-if="item.demand && item.demand.trim() !== ''" style="min-width: 120px !important"> <span
              class="mr-5"
            >SKU:{{ item.sku }}</span>
              备注：{{ item.demand }}</span>
            <span
              v-if="item.demand && item.demand.trim() !== ''"
              style="
                height: 40px !important;
                line-height: 40px !important;
                margin-left: 30px;
              "
            >
              <i class="el-icon-edit mr-3" @click="editNotice(item)" />
              <i class="el-icon-delete" @click="deleteNotice(item.id)" />
            </span>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column :selectable="checkboxT" type="selection" />
      <!-- 平台订单号 -->
      <el-table-column :label="$t('tips.PurchaseOrder')" prop="orderNumber">
        <template slot-scope="scope">
          <span style="color: rgb(30, 159, 255); cursor: pointer" @click="modifyClick(scope.row, true)">
            {{ scope.row.orderNumber }}
          </span>
          <p v-if="scope.row.platform === 'WALMART' || scope.row.platform.toLowerCase() === 'temu'">{{ scope.row.purchaseOrderNumber }}</p>
          <span v-if="scope.row.checkRmaOrder" style="color: red">(RMA待审核)</span>
          <span v-else-if="scope.row.rmaOrder || scope.row.newRmaOrder" style="color: red">(RMA)</span>
        </template>
      </el-table-column>
      <!-- 关联订单号 -->
      <el-table-column :label="$t('title.associatedNumber')">
        <template slot-scope="scope">
          <div v-if="scope.row.sellerOrderNumber">
            <div v-for="(item, index) in scope.row.sellerOrderNumber.split(',')" :key="index">
              {{ item }}
            </div>
          </div>
          <div v-else />
        </template>
      </el-table-column>
      <!--来源单号-->
      <el-table-column :label="$t('order.Sourcenumber')" prop="sourceOrderNumber" />
      <!--  补单号  -->
      <el-table-column :label="$t('title.replenishmentNumber')" prop="sourceOrderNumber">
        <template #default="{row}">
          <div v-if="row.replaceOrderInfoList">
            <span v-for="(e) in row.replaceOrderInfoList" :key="e.replaceOrderNumber">
              {{ e.replaceOrderNumber }}<br>
            </span>
          </div>
        </template>
      </el-table-column>
      <!-- 平台信息 -->
      <el-table-column :label="$t('title.platInfo')" prop="platInfo" width="195">
        <template slot-scope="scope">
          {{ $t('title.platform') }}：{{ scope.row.platform }}
          <br>
          {{ $t('title.site') }}：{{ scope.row.site }}
          <br>
          {{ $t('title.shopName') }}：{{ scope.row.merchant }}
        </template>
      </el-table-column>
      <!-- 订单金额 -->
      <el-table-column :label="$t('title.orderAmount')" align="left" prop="orderAmount" width="150px">
        <template slot-scope="scope">
          <el-tooltip :disabled="!(scope.row.paymentDetailList && scope.row.paymentDetailList.length > 1)" effect="light">
            <template #content>
              支付明细
              <div v-for="(item) in scope.row.paymentDetailList" :key="item.payName">
                {{ item.payName }}：{{ item.amount }}
              </div>
            </template>
            <div>
              {{ $t('title.amount') }}：{{ scope.row.orderPrice }}
              <br>
              {{ $t('title.Freight') }}：{{ scope.row.shippingTax }}
              <br>
              {{ $t('title.amountactuallypaid') }}：{{ scope.row.orderTotal }}
              <br>
              {{ $t('title.Currency') }}：{{ scope.row.currency }}
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <!-- 创建时间 -->
      <el-table-column :label="$t('page.createTime')" width="280px">
        <template slot-scope="scope">
          <!--平台创建时间-->
          <div>{{ $t('page.platformcreatetime') }}：{{
            scope.row.orderCreateDateLocal
          }}
          </div>
          <!--平台付款时间-->
          <div>
            {{ $t('title.platformpaymenttime') }}：{{
              scope.row.paymentsDateLocal
            }}
          </div>
          <!--预计发货时间-->
          <div>
            {{ $t('title.Expecteddateofshipment') }}：<span>{{ scope.row.estimatedShipDateLocal }}</span>
          </div>
          <!--最迟派送时间-->
          <div v-if="scope.row.latestDeliveryMarketTime">
            {{ $t('title.LatestDeliveryTime') }}：{{
              scope.row.latestDeliveryMarketTime
            }}
          </div>
          <!--平台自动取消时间-->
          <div v-if="scope.row.orderAutoCancelMarketTime">
            {{ $t('title.platformAutoCancelTime') }}：{{
              scope.row.orderAutoCancelMarketTime
            }}
          </div>
          <!--系统创建时间-->
          <div>
            {{ $t('page.systemcreatetime') }}：{{
              (scope.row.createDateLocal == undefined || scope.row.createDateLocal == '') ? scope.row.createDate : scope.row.createDateLocal
            }}
          </div>
        </template>
      </el-table-column>
      <!-- 订单数量 -->
      <el-table-column :label="$t('title.quantityorder')" align="center" prop="orderQuantity" sortable width="150" />
      <!-- 主订单状态 -->
      <el-table-column :label="$t('page.status')" align="center" prop="status" width="150px">
        <template slot-scope="scope">
          <!-- 待分配 -->
          <p v-if="scope.row.status === 0">
            {{ $t('title.Tobeallocated') }}
          </p>
          <!-- 分配中 -->
          <p v-if="scope.row.status === 1">
            {{ $t('title.Assigning') }}
          </p>
          <!-- 已分配 -->
          <p v-if="scope.row.status === 2">
            {{ $t('title.Assigned') }}
          </p>

          <!-- 部分发货 -->
          <p v-if="scope.row.status === 3">
            {{ $t('title.Partialshipment') }}
          </p>

          <!-- 已发货 -->
          <p v-if="scope.row.status === 4">
            {{ $t('title.Shippe') }}
          </p>
          <!-- 无法配送 -->
          <p v-if="scope.row.status === 5">
            {{ $t('title.Unabledeliver') }}
          </p>
          <!-- 部分分配 -->
          <p v-if="scope.row.status === 6">
            {{ $t('title.PartOfDistribution') }}
          </p>
          <!-- 产品未匹配 -->
          <p v-if="scope.row.status === 7">
            {{ $t('title.ProductMismatch') }}
          </p>
          <!-- 库存不足 -->
          <p v-if="scope.row.status === 8">
            {{ $t('title.InsufficientInventory') }}
            <span>({{ scope.row.notEnoughStockSubStatus === 0 ? $t('title.lessship'): scope.row.notEnoughStockSubStatus === 1 ? $t('title.MCFCreationFailed') : scope.row.notEnoughStockSubStatus === 2 ? $t('title.FBAHasNotShippedDueDate') : '' }})</span>
          </p>
          <!-- 库存待调 -->
          <p v-if="scope.row.status === 9">
            {{ $t('title.Inventorytostaytuned') }}
          </p>
          <!-- 无法出库 -->
          <p v-if="scope.row.status === 10">
            {{ $t('title.cannotgetoutwarehouse') }}
          </p>
          <!-- 作废中 -->
          <p v-else-if="scope.row.status === 98">
            {{ $t('title.Abolishing') }}
          </p>
          <!-- 已作废 -->
          <p v-else-if="scope.row.status === 97">
            {{ $t('title.Abolished') }}
          </p>
          <!-- 已取消 -->
          <p v-if="scope.row.status === 99">
            {{ $t('title.Cancelleds') }}
          </p>

          <!-- 仓库处理超时 -->
          <p v-if="scope.row.status === 100">
            {{ $t('title.HandleTimeOut') }}
          </p>
          <!-- 上传跟踪号失败 -->
          <p v-if="scope.row.status === 101">
            {{ $t('title.LastTrackingFailed') }}
          </p>
          <!-- 获取跟踪号失败 -->
          <p v-if="scope.row.status === 102">
            {{ $t('title.Gettracefailed') }}
          </p>
          <!-- 配送异常 -->
          <p v-if="scope.row.status === 103">
            {{ $t('title.AbnormalDistribution') }}
          </p>
          <!-- 待审核 -->
          <p v-if="scope.row.status === 201">
            {{ $t('title.ToAudit') }}
          </p>
          <!-- 审核驳回 -->
          <p v-if="scope.row.status === 202">
            {{ $t('title.NotApproved') }}
          </p>
          <!-- 售后订单 -->
          <p v-if="scope.row.status === 301">
            {{ $t('title.Aftersalesserviceorder') }}
          </p>
          <!-- 售后订单 -->
          <p v-if="scope.row.status === 999">
            {{ $t('title.Orderexception') }}
          </p>
          <!-- 客户信息不全 -->
          <p v-if="scope.row.status === 104">
            {{ $t('tips.Incomplete') }}
          </p>
          <!-- 欺诈订单 -->
          <p v-if="scope.row.status === 105">
            {{ $t('tips.Fraudulent') }}
          </p>
        </template>
      </el-table-column>
      <!-- 操作 -->
      <el-table-column :label="$t('page.operate')" align="center">
        <template slot-scope="scope">
          <!--修改-->
          <el-button
            v-if="
              scope.row.status === 0 ||
                scope.row.status === 7 ||
                scope.row.status === 8 ||
                scope.row.status === 6
            "
            el-button
            size="medium"
            type="text"
            @click="modifyClick(scope.row, false)"
          >
            {{ $t('page.modify') }}
          </el-button>
          <!--修改客户信息-->
          <el-button
            v-if="scope.row.status === 104"
            el-button
            size="medium"
            type="text"
            @click="modifyClickBuyerInfo(scope.row)"
          >
            {{ $t('page.modify') }}
          </el-button>
          <!-- 取消 => 作废 -->
          <el-button
            v-if="
              scope.row.status === 0 ||
                scope.row.status === 7 ||
                scope.row.status === 6 ||
                scope.row.status === 8 ||
                scope.row.status === 2||
                scope.row.status === 10 ||
                scope.row.status === 104||
                scope.row.status === 105
            "
            el-button
            size="medium"
            type="text"
            @click="cancelDialogVisibleSure(scope.row)"
          >
            {{ $t('page.invalid') }}
          </el-button>
          <!-- 处理 -->
          <el-button
            v-if="scope.row.status === 7"
            el-button
            size="medium"
            type="text"
            @click="showhandleDialog(scope.row)"
          >
            {{ $t('title.Handle') }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 处理弹框 -->
    <el-dialog :title="$t('title.handleMatched')" :visible.sync="handleDialogVisible" width="60%">
      {{ $t('title.PlatformAccount') }}: {{ row.merchant }}
      <vxe-table
        ref="xTable"
        :data="itemSplitList"
        :edit-config="{ trigger: 'manual', mode: 'row' }"
        align="center"
        class="mb-3 mt-3"
        keep-source
        style="width: 100%"
      >
        <vxe-table-column :title="$t('title.PlatformSku')" field="originalFbmSku" width="200" />
        <vxe-table-column :edit-render="{ name: 'input', attrs: { type: 'text' } }" field="sku" title="SKU" />
        <vxe-table-column :title="$t('title.aQuantity')" field="quantity" />
        <vxe-table-column :title="$t('page.status')" align="center">
          <template v-slot="{ row }">
            <span v-if="row.status === 7">{{
              $t('title.ProductMismatch')
            }}</span>
            <span v-else>{{ $t('title.normal') }}</span>
          </template>
        </vxe-table-column>
        <vxe-table-column :title="$t('page.operate')" align="center">
          <template v-slot="{ row }">
            <!--创建-->
            <template v-if="$refs.xTable.isActiveByRow(row)">
              <vxe-button @click="saveRowEvent(row)">{{ $t('title.Save') }}</vxe-button>
              <vxe-button @click="cancelRowEvent(row)">{{ $t('title.cancel') }}</vxe-button>
            </template>
            <template v-else>
              <vxe-button v-if="row.status === 7" @click="editRowEvent(row)">新建</vxe-button>
              <vxe-button v-else @click="editRowEvent(row)">编辑</vxe-button>
            </template>
          </template>
        </vxe-table-column>
      </vxe-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleDialogVisible = false">取 消</el-button>
        <el-button
          v-loading.fullscreen.lock="productloading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          type="primary"
          @click="handleDialogVisibleSubmit"
        >{{
          $t('title.DistributableInventory')
        }}</el-button>
      </span>
    </el-dialog>
    <!-- FBA发货失败弹窗 -->
    <el-dialog :visible.sync="fbaVisible" title="提示" width="30%">
      <span>FBA库存不足！</span>
    </el-dialog>
    <!-- 库存待调弹窗 -->
    <el-dialog v-show="msg" :visible.sync="distributionWarehousesTobeadjusteddialogVisible" title="提示" width="30%">
      <span v-if="msg">{{ msg }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="distributionWarehousesTobeadjustedCancel">取 消</el-button>
        <el-button type="primary" @click="distributionWarehousesTobeadjustedSure(ROW, ITEM, TYPE)">确 定</el-button>
      </span>
    </el-dialog>
    <create-rma :dialog-visible="visibleflag" @cancelDialogVisible="cancelDialogVisible" />
    <!-- 详情添加备注 -->
    <el-dialog :visible.sync="dialogVisible" title="添加/修改备注" width="30%">
      <el-input v-model="demand" maxlength="250" placeholder="请输入内容" show-word-limit type="textarea" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editNoticeSure(0)">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改SKU -->
    <el-dialog :visible.sync="skuDialog" title="修改SKU" width="50%">
      <el-form :model="skuForm" label-width="110px">
        <el-row :span="24">
          <el-col :span="12">
            <el-form-item label="sku">
              <el-input
                v-model="skuForm.sku"
                :placeholder="$t('page.inputPlaceholder')"
                @input="searchParams(skuForm.sku,'sku')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="平台SKU">
              <el-input
                v-model="skuForm.platSku"
                :placeholder="$t('page.inputPlaceholder')"
                @input="searchParams(skuForm.platSku,'platSku')"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-table
        ref="multipleTable"
        :data="showSkuTableData"
        :header-cell-class-name="cellClass"
        highlight-current-row
        style="width: 100%"
        tooltip-effect="dark"
        @row-click="showRow"
        @selection-change="getCurrentRow"
      >
        <el-table-column
          align="center"
          label="选择"
          type="selection"
          width="55"
        >
          <template slot-scope="scope">
            <el-radio
              v-model="radio"
              :label="scope.$index"
            ><i /></el-radio>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="SKU"
          prop="sku"
        />
        <el-table-column
          align="center"
          label="平台SKU"
          prop="platSku"
        />

      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancleSkuDialog">{{ $t('title.cancel') }}</el-button>
        <el-button :loading="skuLoading" type="primary" @click="submitSku"> {{ $t('title.confirm') }}</el-button>
      </span>
    </el-dialog>
    <!-- 修改客户信息 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('tips.Modifycustomerinfo')"
      :visible.sync="BuyerInfoDialog"
      width="65%"
    >
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="80px">
        <el-row>
          <el-col :span="6">
            <el-form-item :label="$t('order.Receipt')" prop="buyerName">
              <el-input v-model="ruleForm.buyerName" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('order.Country')">
              <el-input v-model="ruleForm.countryCode" :placeholder="$t('page.inputPlaceholder')" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('order.Statesd')" prop="stateRegion">
              <el-input v-model="ruleForm.stateRegion" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('title.city')" prop="city">
              <el-input v-model="ruleForm.city" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('order.Address')" prop="address1ine1">
              <el-input v-model="ruleForm.address1ine1" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('order.address')">
              <el-input v-model="ruleForm.address1ine2" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('order.Zipcode')" prop="postalCode">
              <el-input v-model="ruleForm.postalCode" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('title.phone')" prop="buyerPhone">
              <el-input v-model="ruleForm.buyerPhone" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="email">
              <el-input v-model="ruleForm.buyerEmail" :placeholder="$t('page.inputPlaceholder')" disabled />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancleBuyerInfoDialog">{{ $t('title.cancel') }}</el-button>
        <el-button :loading="buyerInfoLoading" type="primary" @click="submitBuyerInfo"> {{
          $t('title.confirm')
        }}</el-button>
      </span>
    </el-dialog>
    <BeOutStockDialog :visible.sync="beOutStockDialogVisible" :current-row="currentRow" :current-item="currentItem" />
  </div>
</template>

<script>
import CreateRma from '@/components/RMADialog'
import mixin from '@/mixin/oms-mixin'
import { deepClone } from '@/utils'
import {
  cancelorders,
  changeToAdjustedStock,
  getSameStyleColorSize,
  handSplitWarehouse,
  itemDemand,
  modifyCustInfo,
  repairSku,
  replaceSku,
  syncStockAndCheckStock
} from '@/api/omsorder' // import { deepClone } from '@/utils'
// import { deepClone } from '@/utils'
import BeOutStockDialog from '../BeOutStockDialog'
export default {
  name: 'TableInfo',
  filters: {
    ellipsis(value) {
      if (!value) return ''
      if (value.length > 18) {
        // 判断字段
        return value.slice(0, 18) + '...'
      }
      return value
    }
  },
  components: { CreateRma, BeOutStockDialog },
  mixins: [mixin],
  props: {
    tableData: {
      type: Array,
      required: true,
      default: () => []
    },
    pager: {
      type: Object,
      default: () => {
      }
    },
    tableLoading: {
      type: Boolean,
      default: false
    },
    platform: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      expandArrowIcon: 'el-icon-arrow-right',
      radio: '',
      skuForm: {
        sku: '',
        platSku: ''
      },
      BuyerInfoDialog: false,
      buyerInfoLoading: false,
      selectionChangeSku: [],
      skuDialog: false,
      skuLoading: false,
      itemSKU: {},
      replaceSkuItem: null,
      type: '',
      demand: '',
      item: {},
      btnloading: false,
      dialogVisible: false,
      tunedLoading: false,
      fbaloading: false,
      loading: false,
      ROW: {},
      ITEM: {},
      TYPE: 0,
      code: 0,
      distributionWarehousesTobeadjusteddialogVisible: false,
      msg: '',
      visibleflag: false,
      fbaVisible: false,
      title: '',
      handleDialogVisible: false,
      status: '',
      siteCode: '',
      tableDatasLoading: false,
      getRowKeys: (row) => {
        return row.id
      },
      expands: [],
      // gobackDialogVisible: false,
      row: {},
      formInline: {},
      sidvoList: [],
      itemSplitList: [],
      datas: '',
      productloading: false,
      skuTableData: [],
      showSkuTableData: [],
      ruleForm: {
        buyerEmail: '', // 邮箱
        buyerName: '', // 买家姓名
        buyerPhone: '', // 买家电话
        platform: '',
        site: '',
        countryCode: '',
        stateRegion: '',
        city: '',
        address1ine1: '',
        address1ine2: '',
        postalCode: ''
      },
      buyerInfoObj: {},
      rules: {
        buyerName: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        // countryCode: [{ required: true, message: this.$t('page.required'), trigger: ['blur'] }],
        stateRegion: [{ required: true, message: this.$t('page.required'), trigger: ['blur'] }],
        city: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        address1ine1: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        postalCode: [{ required: true, message: this.$t('page.required'), trigger: ['blur'] }],
        // address1ine2: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        buyerPhone: [{ required: true, message: this.$t('page.required'), trigger: ['blur'] }]

      },
      beOutStockDialogVisible: false,
      currentRow: null,
      currentItem: null
    }
  },
  watch: {
    handleDialogVisible(val) {
      console.log(val)
      if (val) {
        const { itemSplitList } = this.row
        this.itemSplitList = itemSplitList
      }
    }
  },
  methods: {
    cellClass(row) {
      if (row.columnIndex === 0) {
        console.log(row.columnIndex)
        return 'disabledCheck'
      }
    },
    async deleteNotice(id) {
      const { code } = await itemDemand({ id, demand: ' ' })
      code === 0 ? this.$message.success('操作成功') : this.$message.error('操作失败')
      this.$emit('refresh')
    },
    editNotice(item) {
      console.log(item)
      this.dialogVisible = true
      this.item = item
      this.dialogVisible = true
      this.demand = item.demand
    },
    async editNoticeSure() {
      const { id } = this.item
      const { code } = await itemDemand({ id, demand: this.demand.trim() })
      code === 0 ? this.$message.success('操作成功') : this.$message.error('操作失败')
      this.dialogVisible = false
      this.$emit('refresh')
    },
    async _changeToAdjustedStock(item) {
      try {
        this.tunedLoading = true
        const { code, msg } = await changeToAdjustedStock(item)
        code === 0
          ? this.$message.success('处理成功')
          : this.$message.error(msg)
        this.$emit('refresh')
        this.tunedLoading = false
      } finally {
        this.tunedLoading = false
      }
    },

    copy(value) {
      var input = document.createElement('input')
      input.value = value
      document.body.appendChild(input)
      input.select()
      document.execCommand('Copy')
      document.body.removeChild(input)
      this.$message.success(this.$t('title.copysuccess'))
    },
    createRMA(row) {
      console.log(row)
      if (row.platform === 'AMAZON') {
        this.$router.push({
          path: '../../rma-manage/return-manage/added',
          append: true,
          query: { 'id': row.id, ...this.userInfo }
          // query: { 'id': this.detailList.id, 'repostType': 2, ...this.userInfo }
        })
      } else if (row.platform === 'EBAY') {
        this.$router.push({
          path: '../../rma-manage/ebay-return-manage/added',
          append: true,
          query: { 'id': row.id, ...this.userInfo }
          // query: { 'id': this.detailList.id, 'repostType': 2, ...this.userInfo }
        })
      } else {
        this.$router.push({
          path: '../../rma-manage/walmart-return-manage/added',
          append: true,
          query: { 'id': row.id, ...this.userInfo }
          // query: { 'id': this.detailList.id, 'repostType': 2, ...this.userInfo }
        })
      }
    },
    toRma(row) {
      const routeData = this.$router.resolve({
        path: '/rma-manage/return-manage',
        append: true,
        query: {
          // 平台站点订单
          orderCode: row.orderNumber,
          platForm: row.platform,
          siteCode: row.site,
          ...this.userInfo
        }
      })
      window.open(routeData.href, '_blank')
    },
    cancelDialogVisible(val) {
      this.visibleflag = val
    },
    // 取消订单
    cancelDialogVisibleSure(row) {
      console.log(row)
      const { id, status, merchant, orderNumber, platform, site } = row
      const obj = { id, status, merchant, orderNumber, platform, site }
      const arr = []
      arr.push(obj)
      this.$confirm(this.$t('title.canceltip'), this.$t('page.Prompt'), {
        confirmButtonText: this.$t('title.confirm'),
        cancelButtonText: this.$t('title.cancel'),
        type: 'warning'
      }).then(async() => {
        const { msg, code } = await cancelorders(arr)
        code === 0 ? this.$message.success(msg) : this.$message.error(msg)
        this.$emit('refresh')
      })
    },
    handleskuDialog(row, item) {
      this.skuDialog = true
      this.itemSKU = row
      this.replaceSkuItem = item
      this._sameStyleColorSize(row.sku)
    },
    async _sameStyleColorSize(sku) {
      const { datas } = await getSameStyleColorSize({ sku })
      this.skuTableData = datas
      this.showSkuTableData = this.skuTableData
    },

    showRow(row) {
      this.radio = this.showSkuTableData.indexOf(row)
      this.selectionChangeSku = []
      this.$refs.multipleTable.clearSelection()
      // 获取选中数据   row表示选中这一行的数据，可以从里面提取所需要的值
      this.selectionChangeSku.push(row)
    },
    getCurrentRow(row) {
      console.log(row)
    },
    searchParams(keyWord, key) {
      var arr = []
      for (var i = 0; i < this.skuTableData.length; i++) {
        if (this.skuTableData[i][key].indexOf(keyWord) >= 0) {
          arr.push(this.skuTableData[i])
        }
      }
      this.showSkuTableData = arr
    },
    cancleSkuDialog() {
      this.skuTableData = []
      this.showSkuTableData = []
      this.skuDialog = false
      this.radio = ''
      this.skuForm = {}
    },
    async submitSku() {
      try {
        this.skuLoading = true
        const arr = this.selectionChangeSku.map((item) => {
          return {
            orderItemId: this.itemSKU.orderItemId,
            platform: this.itemSKU.platform,
            site: this.itemSKU.site,
            merchant: this.itemSKU.merchant,
            id: this.itemSKU.id,
            platSku: item.platSku.trim(),
            sku: item.sku.trim()
          }
        })
        const { code, msg } = await repairSku({
          id: this.replaceSkuItem.id,
          platform: this.replaceSkuItem.platform,
          site: this.replaceSkuItem.site,
          merchant: this.replaceSkuItem.merchant,
          orderNumber: this.replaceSkuItem.orderNumber,
          status: this.replaceSkuItem.status,
          itemSplitListReplace: arr
        })
        if (code === 0) {
          this.$message.success(msg)
          this.skuTableData = []
          this.showSkuTableData = []
          this.skuDialog = false
          this.skuLoading = false
          this.radio = ''
          this.$emit('refresh')
        } else {
          this.$message.error(msg)
          this.skuTableData = []
          this.radio = ''
          this.showSkuTableData = []
        }
        console.log(this.skuLoading)
      } catch (err) {
        if (err) {
          this.skuLoading = false
          this.skuTableData = []
          this.radio = ''
          this.showSkuTableData = []
          this.skuDialog = false
          this.$emit('refresh')
        }
      }
    },
    // 单个订单详情查询
    // 手动分配仓库
    async distributionWarehousesTobeadjusted(row, item, type) {
      try {
        this.loading = true
        this.ROW = row
        this.ITEM = item
        this.TYPE = type
        const arr = []
        const { id, platform, site, merchant, orderNumber, status } = row
        arr.push(item)
        const res = await syncStockAndCheckStock({
          inventoryAllocationType: type,
          id,
          platform,
          site,
          merchant,
          orderNumber,
          status,
          itemSplitListAllocation: arr
        })
        this.code = res.code
        if (res.code === 0) {
          // this.loading = false
          if (res.datas === '') {
            this.distributionWarehouses(row, item, type)
          } else {
            this.msg = res.datas
            this.distributionWarehousesTobeadjusteddialogVisible = true
            this.loading = false
          }
        } else {
          this.msg = res.error
          this.distributionWarehousesTobeadjusteddialogVisible = true
          this.loading = false
        }
      } catch (err) {
        this.loading = false
      }
    },
    distributionWarehousesTobeadjustedSure(row, item, type) {
      this.code === 0 ? this.distributionWarehouses(row, item, type) : ''
      this.distributionWarehousesTobeadjusteddialogVisible = false
    },
    // 取消
    distributionWarehousesTobeadjustedCancel() {
      this.distributionWarehousesTobeadjusteddialogVisible = false
      this.loading = false
    },
    async distributionWarehouses(row, item, type) {
      try {
        this.loading = true
        const arr = []
        const { id, platform, site, merchant, orderNumber, status } = row
        arr.push(item)
        this.$emit('refresh')
        const { code, error } = await handSplitWarehouse({
          inventoryAllocationType: type,
          id,
          platform,
          site,
          merchant,
          orderNumber,
          syncOmsWareStockFlag: false,
          status,
          itemSplitListAllocation: arr
        })
        if (code === 0) {
          this.$message.success('操作成功')
          this.$emit('refresh')
          this.loading = false
        } else {
          this.$message.error(error)
          this.loading = false
        }
      } catch (err) {
        this.loading = false
      }
      // 刷新订单详情
      // } finally {
      //   this.loading = false
      //   this.$emit('refresh')
      // }
    },
    // 修改客户信息
    modifyClickBuyerInfo(row) {
      this.buyerInfoObj = row
      console.log(this.buyerInfoObj)

      const {
        buyerPhone,
        stateRegion,
        city,
        address1ine1,
        address1ine2,
        buyerName,
        countryCode,
        postalCode,
        buyerEmail
      } = row
      this.ruleForm = {
        buyerPhone,
        stateRegion,
        city,
        address1ine1,
        address1ine2: address1ine2 || '',
        buyerName,
        countryCode,
        postalCode,
        buyerEmail
      }
      // const { buyerPhone, stateRegion, city, address1ine1, address1ine2, buyerName, countryCode, postalCode, buyerEmail } = this.ruleForm
      // this.buyerInfoObj = Object.assign({}, { buyerEmail, postalCode, countryCode, buyerName, stateRegion, city, address1ine1, address1ine2, buyerPhone, purchaseOrderNumber: row.purchaseOrderNumber, merchant: row.merchant, orderNumber: row.orderNumber, platform: row.platform, site: row.site },)
      this.BuyerInfoDialog = true
    },
    cancleBuyerInfoDialog() {
      Object.assign(this.ruleForm, this.$options.data.call(this).ruleForm)
      this.BuyerInfoDialog = false
    },

    submitBuyerInfo() {
      this.$refs['ruleForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        const data = Object.assign({}, this.ruleForm, {
          purchaseOrderNumber: this.buyerInfoObj.purchaseOrderNumber ? this.buyerInfoObj.purchaseOrderNumber : '',
          merchant: this.buyerInfoObj.merchant,
          orderNumber: this.buyerInfoObj.orderNumber,
          platform: this.buyerInfoObj.platform,
          site: this.buyerInfoObj.site
        })
        console.log(data)
        const filterObj = deepClone(data)
        for (const key in data) {
          if (data[key] === '' || data[key] === null) {
            delete data[key]
          }
        }
        this._modifyCustInfo(filterObj)
      })
    },
    async _modifyCustInfo(data) {
      try {
        this.buyerInfoLoading = true
        const { code, msg } = await modifyCustInfo(data)
        if (code === 0) {
          this.$message.success(msg)
          this.BuyerInfoDialog = false
          this.buyerInfoLoading = false
          this.$emit('refresh')
          Object.assign(this.ruleForm, this.$options.data.call(this).ruleForm)
        }
      } catch (err) {
        if (err) {
          this.buyerInfoLoading = false
          this.BuyerInfoDialog = false
          Object.assign(this.ruleForm, this.$options.data.call(this).ruleForm)
          this.$emit('refresh')
        }
      } finally {
        this.buyerInfoLoading = false
        this.BuyerInfoDialog = false
      }
    },
    // 修改
    modifyClick(row, disabled) {
      const routeData = this.$router.resolve({
        path: 'added',
        append: true,
        query: {
          status: row.status,
          id: row.id,
          platform: row.platform,
          site: row.site,
          orderId: row.orderId,
          siteCode: this.siteCode,
          disabled: disabled,
          merchant: row.merchant,
          orderNumber: row.orderNumber,
          ...this.userInfo
        }
      })
      window.open(routeData.href, '_blank')
    },
    handleSelectionChange(val) {
      console.log(val)
      this.sidvoList = val
      this.$emit('getMul', val)
    },
    handleExpandAll() {
      const store = this.$refs.table.store
      if (this.expandArrowIcon === 'el-icon-loading' || this.tableData.length === 0) {
        return
      }
      if (this.expandArrowIcon === 'el-icon-arrow-right') {
        // 展开所有行
        this.expandArrowIcon = 'el-icon-loading'
        setTimeout(() => {
          this.tableData.forEach((item) => {
            store.toggleRowExpansion(item, true)
          })
          this.expandArrowIcon = 'el-icon-arrow-down'
        }, 500)
      } else {
        this.expandArrowIcon = 'el-icon-arrow-right'
        this.tableData.forEach((item) => {
          store.toggleRowExpansion(item, false)
        })
      }
    },
    checkboxT(row, rowIndex) {
      // 库存不足/待分配时并且该订单不存在已分配的明细 禁用
      return true
    },
    showcancelDialog() {
      this.cancelDialogVisible = true
    },
    showhandleDialog(row) {
      this.row = row
      let { itemSplitList } = this.row
      itemSplitList = itemSplitList.map((item) => {
        return { ...item, quantity: 1 }
      })
      this.itemSplitList = itemSplitList
      this.handleDialogVisible = true
    },
    // 处理匹配 创建
    showcreateDialog(row) {
      this.creatematchDialogVisible = true
    },
    editRowEvent(row) {
      console.log(111)
      console.log(row)
      console.log(this.$refs.xTable)
      console.log(this.$refs.xTable.setActiveRow(row))
      this.$refs.xTable.setActiveRow(row)
    },
    cancelRowEvent(row) {
      const xTable = this.$refs.xTable
      xTable.clearActived().then(() => {
        // 还原行数据
        xTable.revertData(row)
      })
    },
    saveRowEvent(row) {
      console.log(row)
      this.$refs.xTable.clearActived().then(async() => {
        let arr = []
        arr.push(row)
        arr = arr.map((item) => {
          return { ...item, platSku: item.sku.trim() }
        })
        await this.$confirm(this.$t('title.Notice'), this.$t('page.Prompt'), {
          confirmButtonText: this.$t('title.confirm'),
          cancelButtonText: this.$t('title.cancel'),
          type: 'warning'
        })

        const res = await replaceSku({
          platform: this.row.platform,
          site: this.row.site,
          merchant: this.row.merchant,
          orderNumber: this.row.orderNumber,
          id: this.row.id,
          status: this.row.status,
          itemSplitListReplace: arr
        })
        res.code === 0
          ? this.$message.success(res.msg)
          : this.$message.error(this.$t('title.createfail'))
        res.code === 0 ? row.status = 88 : ''
        this.$emit('refresh')
      })
    },
    handleDialogVisibleSubmit() {
      this.itemSplitList = this.itemSplitList.map((item) => {
        return { ...item, platSku: item.sku.trim() }
      })
      console.log(this.itemSplitList)
      // 判断sku都已填
      if (this.itemSplitList.find((item) => item.platSku === undefined)) {
        return this.$message.error(this.$t('title.everySKu'))
      }
      // 判断是否都已点保存
      if (this.itemSplitList.find((item) => item.status === 7)) {
        return this.$message.error(this.$t('title.savefirst'))
      }
      this.$confirm(this.$t('title.Notice'), this.$t('page.Prompt'), {
        confirmButtonText: this.$t('title.confirm'),
        cancelButtonText: this.$t('title.cancel'),
        type: 'warning'
      }).then(async() => {
        try {
          this.productloading = true
          const res = await handSplitWarehouse({
            status: this.row.status,
            id: this.row.id,
            itemSplitListAllocation: this.itemSplitList,
            inventoryAllocationType: 1,
            platform: this.row.platform,
            site: this.row.site,
            merchant: this.row.merchant,
            orderNumber: this.row.orderNumber
          })
          console.log(res)
          this.$emit('refresh')
          this.handleDialogVisible = false
          this.productloading = false
        } catch (err) {
          this.productloading = false
        }
      })
    },
    handleBeOutofStock(row, item) {
      this.beOutStockDialogVisible = true
      this.currentRow = row
      this.currentItem = item
    }
  }
}
</script>

<style lang="scss" scoped>
.specification {
  min-width: 100px !important;
}

.el-button--text {
  margin-left: 10px;
}

/deep/ .el-table__expanded-cell {
  padding: 0px !important;
}

/deep/ .expand-row {
  line-height: 25px !important;
}

/deep/ .operate .el-button--medium {
  padding: 5px;
}

/deep/ .disabledCheck > .cell .el-checkbox__inner {
  display: none !important;
}
</style>
