<template>
  <el-row class="select-info my-2 px-2" :class="quantityStyle">
    <el-col :span="12">
      <i class="el-icon-info" />
      <span class="ml-2">{{ $t('page.alreadySel') }} {{ quantity }} {{ $t('page.rowS') }}</span>
      <el-button :disabled="quantity === 0" type="text" class="ml-2" @click="() => $emit('reset')">{{ $t('page.empty') }}</el-button>
    </el-col>
  </el-row>
</template>

<script>
export default {
  props: {
    quantity: {
      type: Number,
      default: 0
    }
  },
  computed: {
    quantityStyle() {
      return {
        info: this.quantity === 0,
        primary: this.quantity > 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.select-info {
  border-radius: 6px;
}
.primary {
  background-color:#e6f7ff ;
  border: 1px solid #bae7ff;
  .el-icon-info {
    color: #1890ff;
  }
}
.info {
  background-color:#f4f4f5 ;
  border: 1px solid #dcdcdc;
  .el-icon-info, span {
    color: #909399;
  }
}
</style>
