<template>
  <div>
    <el-dialog
      title="创建RMA"
      :before-close="cancelDialogVisible"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="70%"
    >
      <el-row>
        <h3>{{ $t("title.orderinfo") }}</h3>
        <el-form ref="creatermaForm" :model="creatermaForm" label-width="100px" :rules="rules">
          <el-col :span="10">
            <el-form-item label="平台订单号:" prop="platFormName">
              <el-input v-model="creatermaForm.orderCode" placeholder="输入单号后，点击Enter，显示平台等信息" @keyup.enter.native="pickOrdersearch(val)" />
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="平台:" prop="platFormName">
              <el-input v-model="creatermaForm.platFormName " disabled />
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="站点:" prop="siteCode">
              <el-input v-model="creatermaForm.siteCode" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="账号:" prop="shopCode">
              <el-input v-model="creatermaForm.shopCode" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="买家ID:" prop="buyerUsername">
              <el-input v-model="creatermaForm.buyerUsername" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item v-if="creatermaForm.returnDetailType === '0'" label="退货仓:" prop="shipperAddress">
              <el-select
                v-model="creatermaForm.shipperAddress"
                clearable
                filterable
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in warehouseOptions"
                  :key="item.bizKey"
                  :label="item.bizValue"
                  :value="item.bizKey"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="RMA类型:" prop="returnDetailType">
              <el-radio-group v-model="creatermaForm.returnDetailType">
                <el-radio label="0">退货退款</el-radio>
                <el-radio label="1">仅退款(未发货)</el-radio>
                <el-radio label="2">仅退款(已发货)</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="RMA方式:" prop="returnType">
              <el-radio-group v-model="creatermaForm.returnType">
                <el-radio label="0" disabled>系统</el-radio>
                <el-radio label="1">手工</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <el-row>
        <h3>退款信息</h3>
        <el-form ref="creatermaForm" :model="creatermaForm" label-width="100px" :rules="rules">
          <el-col :span="20">
            <el-form-item label="退款类型:" prop="refundType">
              <el-radio-group v-model="creatermaForm.refundType">
                <el-radio v-if="creatermaForm.returnDetailType == 0" label="0">同意退货退款</el-radio>
                <span v-else-if="creatermaForm.returnDetailType == 1 || creatermaForm.returnDetailType == 2">
                  <el-radio label="1">全额退款</el-radio>
                  <el-radio label="2">部分退款</el-radio>
                  <el-radio label="3">拒绝</el-radio>
                </span>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <el-row>
        <h3>产品信息</h3>
        <template>
          <el-table
            ref="multipleTable"
            :data="orderData.itemList"
            default-expand-all
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
            />
            <el-table-column
              label="退款信息"
              align="center"
            >
              <template slot-scope="props">
                <div style="display:flex">
                  <el-image
                    :src="props.row.mainImage"
                    style="width: 45px; height: 45px; vertical-align: middle"
                    class="mr-3"
                  />
                  <p style="overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">{{ props.row.sku }}</p>
                </div></template>
            </el-table-column>
            <el-table-column
              prop="itemQuantity"
              label="退款数量"
              width="100"
              align="center"
            >
              <template slot-scope="props">
                <p style="display:flex;align-items: center;"> <el-input v-model="props.row.itemQuantity" oninput="value=value.replace(/[^0-9.]/g,'')" @input="handlecChangeInput(props.$index)" @change="changNumbers(props.$index)" /></p>
              </template>
            </el-table-column>
            <el-table-column
              label="退款原因"
              width="200"
              align="center"
            >
              <template slot-scope="props">
                <el-select
                  v-model="props.row.returnReasonCode"
                  clearable
                  filterable
                  :placeholder="$t('page.selectPlaceholder')"
                >
                  <el-option
                    v-for="item in returnReasonOptions"
                    :key="item.returnReasonCodeEN"
                    :label="item.returnReasonCodeCN"
                    :value="item.returnReasonCodeEN"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              label="订单金额"
              width="150"
            >
              <template slot-scope="props">
                <p style="display:flex;align-items: center;"><span> $ </span> <span>{{ props.row.itemPrice }}</span></p>
              </template>
            </el-table-column>

          </el-table>
          <el-form ref="creatermaForm" :model="creatermaForm" label-width="100px" style="margin-top:10px">
            <el-col :span="15">
              <el-form-item label="买家留言:">
                <el-input
                  v-model="creatermaForm.sellComments"
                  type="textarea"
                  :rows="2"
                  placeholder="请输入内容"
                />
              </el-form-item>
            </el-col>
            <el-col :span="15">
              <el-form-item label="系统备注:">
                <el-input
                  v-model="creatermaForm.remark"
                  type="textarea"
                  :rows="2"
                  placeholder="请输入内容"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :class="$i18n.locale" label="买家图片">
                <el-upload
                  ref="upload"
                  :action="action"
                  list-type="picture-card"
                  :file-list="sellerFileList"
                  :before-remove="beforeRemoved"
                  :on-preview="handlePictureCardPreview"
                  :headers="uploadHeaders"
                  :on-remove="handleRemove"
                  :on-success="handleSuccess"
                >
                  <i class="el-icon-upload" />
                </el-upload>
                <el-dialog :visible.sync="imgdialogVisible" append-to-body>
                  <img width="100%" :src="dialogImageUrl" alt="">
                </el-dialog>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :class="$i18n.locale" label="卖家图片">
                <el-upload
                  :action="action"
                  :headers="uploadHeaders"
                  list-type="picture-card"
                  :on-preview="handlePictureCardPreview1"
                  :before-remove="beforeRemoved1"
                  :on-remove="handleRemove1"
                  :on-success="handleSuccess1"
                  :file-list="brcfilelist"
                >

                  <i class="el-icon-upload" />
                </el-upload>
                <el-dialog :visible.sync="imgdialogVisible">
                  <img width="100%" :src="dialogImageUrl1" alt="">
                </el-dialog>
              </el-form-item>
            </el-col>
          </el-form>
        </template>

      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDialogVisible">取 消</el-button>
        <el-button type="primary" @click="saveReturn">保 存</el-button>
        <el-button type="primary" @click="submitReturn">发布</el-button>
      </span>
    </el-dialog>
    <!-- 退款弹窗 -->
    <el-dialog
      title="退款类型操作"
      :visible.sync="refundDialogVisible"
      width="80%"
    >
      <el-row>
        <p v-if="creatermaForm.refundType === '1'">全部退款</p>
        <p v-if="creatermaForm.refundType === '2'">部分退款</p>
        <p><span>订单号:</span><span>{{ creatermaForm.orderCode }}</span></p>
        <p><span>创建时间:</span><span>{{ detailList.ts }}</span></p>
        <p><span>买家ID:</span><span>{{ creatermaForm.buyerUsername }}</span></p>
        <template>
          <el-table
            id="refund"
            :data="detailList"
            default-expand-all
            style="width: 100%"
          >
            <el-table-column type="expand">
              <template slot-scope="props">
                <p style="display: flex;align-items: center;"><span style="display: inline-block;width: 81px;">系统备注</span>
                  <el-input
                    v-model="props.row.sellComments"
                    type="textarea"
                    :rows="2"
                    placeholder="请输入内容"
                    style="width: 350px;"
                  /></p>
              </template>
            </el-table-column>

            <el-table-column
              label="退款信息"
            >
              <template slot-scope="props">
                <div style="display:flex">
                  <el-image
                    :src="props.row.mainImage"
                    style="width: 35px; height: 35px; vertical-align: middle"
                    class="mr-3"
                  />
                  <p style="overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">{{ props.row.sku }}</p>
                </div></template>
            </el-table-column>
            <el-table-column
              prop="returnNumber"
              label="退款数量"
              width="80"
            />
            <el-table-column
              label="退款原因"
              width="160"
            >
              <template slot-scope="props">
                <el-select
                  v-model="props.row.returnReasonCode"
                  disabled
                  clearable
                  filterable
                  :placeholder="$t('page.selectPlaceholder')"
                >
                  <el-option
                    v-for="item in returnReasonOptions"
                    :key="item.returnReasonCodeEN"
                    :label="item.returnReasonCodeCN"
                    :value="item.returnReasonCodeCN"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              label="订单金额"
              width="100"
            >
              <template slot-scope="props">
                <p style="display:flex;align-items: center;"><span> $ </span> <el-input v-model="props.row.itemPrice" oninput="value=value.replace(/[^0-9.]/g,'')" @input="changeInput(props.$index)" @change="changeNumber(props.$index)" /></p>
              </template>
            </el-table-column>
            <el-table-column
              prop="itemTax"
              label="税费"
              width="80"
            >
              <template slot-scope="props">
                <p style="display:flex;align-items: center;"><span> $ </span> <span>{{ props.row.itemTax?props.row.itemTax:0 }}</span></p>
              </template>
            </el-table-column>
            <el-table-column
              label="运费退费"
              width="100"
            >
              <template slot-scope="props">
                <p style="display:flex;align-items: center;"><span> $ </span> <el-input v-model="props.row.shippingPrice" oninput="value=value.replace(/[^0-9.]/g,'')" @input="changeInput(props.$index)" @change="changeNumber(props.$index)" /></p>
              </template>
            </el-table-column>
            <el-table-column
              label="促销折扣"
              width="100"
            >
              <template slot-scope="props">
                <p style="display:flex;align-items: center;"><span> $ </span> <el-input v-model="props.row.promotionDiscount" oninput="value=value.replace(/[^0-9.]/g,'')" @input="changeInput(props.$index)" @change="changeNumber(props.$index)" /></p>
              </template>
            </el-table-column>
            <el-table-column
              label="额外退费"
              width="100"
            >
              <template slot-scope="props">
                <p style="display:flex;align-items: center;"><span> $ </span> <el-input v-model="props.row.otherRefund" oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="20" @input="changeInput(props.$index)" @change="changeNumber(props.$index)" /></p>
              </template>
            </el-table-column>
            <el-table-column
              prop="refundAmount"
              label="总金额"
              width="100"
            >
              <template slot-scope="props">
                <p style="display:flex;align-items: center;"><span> $ </span> <span>{{ props.row.refundAmount }}</span></p>
              </template>
            </el-table-column>

          </el-table>
        </template>

      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="refundDialogVisible = fasle">取 消</el-button>
        <el-button type="primary" @click="saveRefund">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getAllReasonCode, getOmsSelfDeliveryOrderItemTwoLevel, listSysBaseConfig, saveReturnInfos } from '@/api/omsorder'
export default {
  name: 'RMALableDialog',
  components: {},
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    order: {
      type: Object,
      default: () => ({})
    }

  },
  data() {
    return {
      disabled: true,
      dialogImageUrl: '',
      dialogImageUrl1: '',
      sellerFileList: [],
      brcfilelist: [],
      refundDialogVisible: false,
      uploadHeaders: {
        authorization: this.$store.getters.authorization
      },
      sellerImageUrl: [],
      imageUrl: [],
      returnReasonOptions: [],
      action: process.env.VUE_APP_UPLOAD_API,
      imgdialogVisible: false,
      orderData: [],
      detailList: [],
      returnReasonCodeOptions: [],
      rmaId: '',
      flag: true,
      warehouseOptions: [],
      creatermaForm: {
        orderCode: '',
        siteCode: '',
        shopCode: '',
        platFormName: '',
        buyerUsername: '',
        shipperAddress: '',
        returnDetailType: '0',
        returnType: '1',
        refundType: '0'

      },
      id: '',
      rules: {
        orderCode: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        siteCode: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        shopCode: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        platFormName: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        buyerUsername: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        shipperAddress: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        returnDetailType: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        returnType: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        refundType: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        returnReasonCode: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }]
      }
    }
  },
  watch: {
    'order'(order) {
      if (order) {
        this.$nextTick(() => {
          if (this.order) {
            const { rmaId, id, returnDetailType, orderCode, returnType, shipperAddress, platFormName, siteCode, sellComments, refundType, buyerUsername } = this.order
            this.creatermaForm.orderCode = orderCode
            this.creatermaForm.siteCode = siteCode
            this.creatermaForm.shopCode = buyerUsername
            this.creatermaForm.platFormName = platFormName
            this.creatermaForm.buyerUsername = buyerUsername
            this.creatermaForm.shipperAddress = shipperAddress
            this.creatermaForm.returnDetailType = String(returnDetailType)
            this.creatermaForm.returnType = String(returnType)
            this.creatermaForm.refundType = String(refundType)
            this.creatermaForm.sellComments = sellComments
            this.orderData.itemList = this.order.detailList
            this.id = id
            this.rmaId = rmaId
            this.orderData.itemList.map(e => {
              e.itemQuantity = e.returnNumber
              this.creatermaForm.refundType = String(e.refundType)
              e.refundAmount = 0
            })
            this.$nextTick(() => {
              this.orderData.itemList.forEach(i => {
                this.$refs['multipleTable'].toggleRowSelection(i, true)
              })
            })
            this.order.imageList.map(e => {
              if (e.imageType === 0) {
                const index = e.imageUrl.lastIndexOf('\/')
                const fileName = e.imageUrl.substring(index + 1, e.imageUrl.length)
                this.sellerFileList.push({
                  name: fileName,
                  url: e.imageUrl
                })
              }
              console.log(e.imageType)
            })
            console.log(this.sellerFileList)
          }
          this._getAllReasonCode(this.order.platFormName)
        })
      }
    }

  },
  created() {

  },
  // updated() {

  // },
  mounted() {
    this._listSysBaseConfig()
  },
  methods: {
    changeRadio(val) {
      console.log(val)
    },
    // 选中明细数据val
    handleSelectionChange(val) {
      console.log(val)
      this.detailList = val
      this.detailList.map(e => {
        e.otherRefund = 0
        e.promotionDiscount = 0
        e.refundAmount = parseFloat(e.itemPrice) - Math.abs(parseFloat(e.promotionDiscount)) + parseFloat(e.otherRefund)
      })
    },
    // 获退款退货原因
    async _getAllReasonCode(platform) {
      const { datas } = await getAllReasonCode(platform)
      this.returnReasonOptions = datas
    },
    // 在输入框输入单号后点击回车调接口查询单号对应的数据
    pickOrdersearch(parames) {
      console.log(this.creatermaForm.orderCode)
      this._getOmsSelfDeliveryOrderItemTwoLevel({ orderNumber: this.creatermaForm.orderCode })
    },
    // 点击单号获取对应的数据信息
    async _getOmsSelfDeliveryOrderItemTwoLevel(parames) {
      const { datas } = await getOmsSelfDeliveryOrderItemTwoLevel(parames)
      if (datas) {
        this.orderData = datas
        this.orderData.itemList.map(e => {
          e.MyitemQuantity = e.itemQuantity
        })
        const { platform, site, merchant, buyerName } = datas
        this.creatermaForm.siteCode = site
        this.creatermaForm.shopCode = merchant
        this.creatermaForm.platFormName = platform
        this.creatermaForm.buyerUsername = buyerName
      }
      this._getAllReasonCode(this.creatermaForm.platFormName)
    },

    // 获取退货仓信息
    async _listSysBaseConfig(data) {
      const { datas } = await listSysBaseConfig({ bizType: 'Return_Warehouse' })
      this.warehouseOptions = datas
    },
    // 保存创建的RMA单
    saveReturn() {
      if (this.detailList.length <= 0) {
        this.$confirm('商品信息不能为空', '提示', {
          confirmButtonText: this.$t('page.sure'),
          cancelButtonText: this.$t('title.cancel')
        })
        return
      }

      const data = {}
      var imgList = []
      this.$refs['creatermaForm'].validate(async(valid) => {
        if (valid) {
          console.log(this.sellerFileList)
          this.sellerFileList.map(e => {
            imgList.push({
              imageUrl: e.url,
              imageType: 0
            })
          })
          this.brcfilelist.map(e => {
            imgList.push({
              imageUrl: e.url,
              imageType: 1
            })
          })

          const { remark, returnDetailType, orderCode, returnType, siteCode, shopCode, sellComments, platFormName, buyerUsername, shipperAddress } = this.creatermaForm
          this.detailList.map(e => {
            e.returnNumber = e.itemQuantity
            e.orderCode = e.orderNumber
            e.refundType = Number(this.creatermaForm.refundType)
          })
          data.detailList = this.detailList
          // [{ 'imageUrl': this.sellerImageUrl, 'imageType': 0 }]
          data.imageList = imgList
          const id = this.id
          const rmaId = this.rmaId
          if (this.id) {
            data.returnsInfo = { rmaId, id, remark, returnDetailType, sellComments, orderCode, siteCode, shopCode, returnType, platFormName, buyerUsername }
          } else {
            data.returnsInfo = { remark, returnDetailType, orderCode, siteCode, shopCode, returnType, sellComments, platFormName, buyerUsername }
          }
          data.returnsTrack = { shipperAddress, orderCode }
          data.operating = 0
          console.log(data)
          this._saveReturnInfos(data)
        }
      })
    },
    // 发布
    submitReturn() {
      if (this.detailList.length <= 0) {
        this.$confirm('商品信息不能为空', '提示', {
          confirmButtonText: this.$t('page.sure'),
          cancelButtonText: this.$t('title.cancel')
        })
        return
      }
      const data = {}
      var imgList = []
      if (this.creatermaForm.refundType === '1' || this.creatermaForm.refundType === '2') {
        this.detailList.map(e => {
          e.returnNumber = e.itemQuantity
          e.orderCode = e.orderNumber
          // e.returnReasonCodeEN = e.returnReasonCode
          // e.itemTax = e.itemTax
          // e.otherRefund = e.otherRefund
          e.refundType = Number(this.creatermaForm.refundType)
        })
        this.refundDialogVisible = true
      } else {
        this.$refs['creatermaForm'].validate(async(valid) => {
          if (valid) {
            this.sellerFileList.map(e => {
              imgList.push({ imageUrl: e.url,
                imageType: 0 })
            })
            this.brcfilelist.map(e => {
              imgList.push({ imageUrl: e.url,
                imageType: 1 })
            })
            const { remark, returnDetailType, sellComments, orderCode, returnType, siteCode, shopCode, platFormName, buyerUsername, shipperAddress } = this.creatermaForm
            this.detailList.map(e => {
              e.returnNumber = e.itemQuantity
              e.orderCode = e.orderNumber
              e.refundType = Number(this.creatermaForm.refundType)
            })
            data.detailList = this.detailList
            data.imageList = imgList
            const id = this.id
            if (this.id) {
              data.returnsInfo = { id, remark, returnDetailType, sellComments, orderCode, siteCode, shopCode, returnType, platFormName, buyerUsername }
            } else {
              data.returnsInfo = { remark, returnDetailType, orderCode, siteCode, shopCode, returnType, sellComments, platFormName, buyerUsername }
            }
            // data.returnsInfo = { remark, returnDetailType, sellComments, orderCode, siteCode, shopCode, returnType, platFormName, buyerUsername }
            data.returnsTrack = { shipperAddress, orderCode }
            data.operating = 1

            console.log(data)
            this._saveReturnInfos(data)
          }
        })
      }
    },
    saveRefund() {
      const data = {}
      var imgList = []
      const { remark, returnDetailType, sellComments, orderCode, returnType, siteCode, shopCode, platFormName, buyerUsername, shipperAddress } = this.creatermaForm
      this.sellerFileList.map(e => {
        imgList.push({ imageUrl: e.url,
          imageType: 0 })
      })
      this.brcfilelist.map(e => {
        imgList.push({ imageUrl: e.url,
          imageType: 1 })
      })
      this.detailList.map(e => {
        e.returnNumber = e.itemQuantity
        e.orderCode = e.orderNumber
        e.refundType = this.creatermaForm.refundType
      })
      data.detailList = this.detailList
      data.imageList = imgList
      const id = this.id
      if (this.id) {
        data.returnsInfo = { id, remark, returnDetailType, sellComments, orderCode, siteCode, shopCode, returnType, platFormName, buyerUsername }
      } else {
        data.returnsInfo = { remark, returnDetailType, orderCode, siteCode, shopCode, returnType, sellComments, platFormName, buyerUsername }
      }
      data.returnsTrack = { shipperAddress, orderCode }
      data.operating = 1
      this._saveReturnInfos(data)
    },
    // 保存或者发布后的返回信息
    async _saveReturnInfos(data) {
      const { code, msg } = await saveReturnInfos(data)
      if (code === 0) {
        this.$notify({
          title: msg,
          message: msg,
          type: 'success'
        })
        this.cancelDialogVisible()
        this.refundDialogVisible = false
        this.$emit('refresh')
      }
    },
    // 查询退货仓
    cancelDialogVisible() {
      this.$emit('cancelDialogVisible', false)
    },
    // 点击已上传的图片时的方法 看大图
    handlePictureCardPreview(file) {
      this.ruleForm.siteImageUrl = file.url
      this.dialogVisible = true
    },
    // 上传图片成功时的方法
    handleSuccess(files, filelist) {
      // 将上传成功的图片放到提交给后台的参数中
      this.sellerFileList.push({
        name: filelist.name,
        url: files.datas
      })
    },
    // 移除图片之前的方法
    beforeRemoved(file, filelist) {
      return this.$confirm(`${this.$t('page.SureDelete')} ${file.name}？`)
    },
    // 移除图片之前的方法
    beforeRemoved1(file, filelist) {
      return this.$confirm(`${this.$t('page.SureDelete')} ${file.name}？`)
    },
    // 点击已上传的图片时的方法 看大图
    handlePictureCardPreview1(file) {
      this.ruleForm.siteImageUrl = file.url
      this.dialogVisible = true
    },
    // 上传图片成功时的方法
    handleSuccess1(files, filelist) {
      // 将上传成功的图片放到提交给后台的参数中
      this.brcfilelist.push({
        name: filelist.name,
        url: files.datas
      })
    },
    handleRemove(file, fileList) {
      if (file.status === 'success') {
        this.sellerFileList = fileList
      }
    },
    handleRemove1(file, fileList) {
      // this.hideUpload1 = fileList.length >= this.limitCount1
      if (file.status === 'success') {
        if (file.status === 'success') {
          this.brcfilelist = fileList
        }
      }
    },

    changeInput(index) {
      var data = this.detailList[index]
      this.$set(this.detailList, index, data)
    },
    handlecChangeInput(index) {
      var data = this.orderData.itemList[index]
      this.$set(this.orderData.itemList, index, data)
    },
    changeNumber(index) {
      var data = this.detailList[index]
      // 订单金额不能大于原本金额
      if (parseFloat(data.itemPrice) > data.myItemPrice) {
        this.$confirm('你输入得金额不能大于原始金额', '提示', {
          confirmButtonText: this.$t('page.sure'),
          cancelButtonText: this.$t('title.cancel')
        })
        data.itemPrice = data.myItemPrice
        return
      }
      if (parseFloat(data.otherRefund) > 20) {
        this.$confirm('您输入的不能大于20', '提示', {
          confirmButtonText: this.$t('page.sure'),
          cancelButtonText: this.$t('title.cancel')
        })
        data.otherRefund = 0
        return
      }
      // 计算总金额
      data.refundAmount = (parseFloat(data.itemPrice) - Math.abs(parseFloat(data.promotionDiscount)) + parseFloat(data.otherRefund) - parseFloat(data.shippingPrice)).toFixed(2)
      this.$set(this.detailList, index, data)
    },
    changNumbers(index) {
      console.log(11111)
      var data = this.orderData.itemList[index]
      console.log(data)
      console.log(Number(data.itemQuantity))
      // 订单金额不能大于原本金额
      if (Number(data.itemQuantity) > data.MyitemQuantity) {
        this.$confirm('您输入得数量不能大于原始数量', '提示', {
          confirmButtonText: this.$t('page.sure'),
          cancelButtonText: this.$t('title.cancel')
        })
        data.itemQuantity = data.MyitemQuantity
        return
      }
      console.log(Number(data.itemQuantity) <= 0)
      if (Number(data.itemQuantity) <= 0) {
        this.$confirm('您输入得数量不能小于0', '提示', {
          confirmButtonText: this.$t('page.sure'),
          cancelButtonText: this.$t('title.cancel')
        })
        data.itemQuantity = data.MyitemQuantity
        return
      }
      this.$set(this.orderData.itemList, index, data)
    }

  }
}
</script>

<style scoped lang="scss">
/deep/ .el-upload--picture-card {
    width: 75px;
    height: 75px;
    cursor: pointer;
    line-height:75px;
}
.hide .el-upload--picture-card {
  display: none;
}
</style>
