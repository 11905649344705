<template>
  <el-dialog
    :before-close="handleClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    title="确认缺货"
    :visible.sync="visible"
    center
    inline
    top="5vh"
    width="800px"
  >
    <el-form
      ref="formRef"
      :model="form"
      :rules="rules"
      label-width="110px"
      label-position="top"
    >
      <el-form-item label="已选取缺货商品">
        <vxe-table
          :data="beOutStockList"
          :show-overflow="false"
          align="center"
          resizable
          :scroll-x="{ enabled: false }"
          :scroll-y="{ enabled: false }"
          height="150px"
          border
        >
          <vxe-column field="sku" title="SKU" />
          <vxe-column field="platSku" title="平台SKU" />
          <vxe-column field="model" title="规格型号" />
          <vxe-column field="itemQuantity" title="要货量" />
          <vxe-column field="status" title="状态" />
        </vxe-table>
      </el-form-item>
      <!-- <el-form-item
        label="该订单中同SKU同步缺货"
        prop="skuSync"
        class="sync-item"
      >
        <el-radio-group v-model="form.skuSync">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="2">否</el-radio>
        </el-radio-group>
      </el-form-item> -->
      <el-form-item class="tips-item">
        <div>确认无货后，Shein会自动执行如下操作</div>
        <div>1、当前SKU库存将清零</div>
        <div>2、确认无货的订单，当前SKU商品将标记为“无货”，无需发货</div>
        <div>
          3、其他订单如包含当前SKU商品，将标记为“缺货”，请及时补货处理，避免超时取消（其他商品可正常发货）
        </div>
      </el-form-item>
    </el-form>
    <template #footer>
      <div style="text-align: right">
        <el-button
          :loading="submitLoading"
          @click="handleClose"
        >取消</el-button>
        <el-button
          type="primary"
          :loading="submitLoading"
          @click="handleConfirm"
        >确定</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { confirmNoStock } from '@/api/omsorder'
export default {
  name: 'BeOutStockDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    currentRow: {
      type: Object,
      default: () => ({})
    },
    currentItem: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      form: {
        // skuSync: 1,
      },
      beOutStockList: [],
      submitLoading: false,
      rules: {}
    }
  },
  computed: {
    submitParams() {
      // const beOutStockList = this.beOutStockList;
      const { platform, site, merchant, orderNumber } = this.currentRow
      const { id } = this.currentItem
      return {
        platform,
        site,
        merchant,
        orderNumber,
        ...this.form,
        itemIdList: [id]
      }
    }
  },
  watch: {
    visible(val) {
      if (val) {
        const { sku, platSku, style, color, sizes, itemQuantity, status } =
          this.currentItem

        const statusEnum = {
          10: '无法出库',
          6: '缺货'
        }

        this.beOutStockList = [
          {
            sku,
            platSku,
            model: `${style} ${color} ${sizes}`,
            itemQuantity,
            status: statusEnum[status]
          }
        ]
      }
    }
  },
  methods: {
    handleClose() {
      this.$refs.formRef.resetFields()
      this.$emit('update:visible', false)
      this.$emit('update:currentRow', null)
      this.$emit('update:currentItem', null)
    },
    handleConfirm() {
      this.$refs.formRef.validate(async(valid) => {
        if (valid) {
          try {
            this.submitLoading = true
            const { msg, code } = await confirmNoStock(
              this.submitParams
            ).finally(() => {
              this.submitLoading = false
            })
            if (code === 0) {
              this.$message({
                type: 'success',
                message: msg
              })
              this.handleClose()
            } else {
              this.$message.success(msg || '操作失败')
            }
          } catch (error) {
            console.log(error)
          }
        }
      })
    }
  }
}
</script>

<style scoped>
::v-deep .el-dialog--center {
  text-align: left;
}
.sync-item {
  display: flex;
  & > div {
    margin-left: 10px;
  }
}
.tips-item {
  background: #dedede;
  border-radius: 5px;
  padding: 0 10px;
  color: #606266;
  font-weight: 700;
}
</style>
