<template>
  <!-- 自发货 -->
  <div class="app-container">
    <el-row>
      <el-col v-if="terraceData.length > 0" :span="24" style="display: flex; flex-wrap: wrap; align-items: center; overflow-x: auto">
        <span>{{ $t('title.Orderplatform') }}:</span>
        <span
          :class="{ active: form.platform === '' }"
          style="
            text-align: center;
            display: block;
            width: 80px;
            height: 35px;
            line-height: 35px;
          "
          @click="selectAll('terrace')"
        >{{ $t('title.OrderAll') }}</span>
        <div v-for="item in terraceData" :key="item.id">
          <span
            :class="{ active: form.platform === item.platformCode }"
            style="
              text-align: center;
              display: block;
              padding:0 10px ;
              height: 35px;
              line-height: 35px;
            "
            @click="selectTerrace('terrace', item)"
          >{{ item.platformShortName }}</span>
        </div>
      </el-col>
    </el-row>
    <el-row style="margin: 20px 0">
      <el-col v-if="siteData.length > 0" :span="24" style="display: flex; flex-wrap: wrap; align-items: center; overflow-x: auto">
        <span>{{ $t('title.Platformsite') }}:</span>
        <span
          :class="{ active: form.site === '' }"
          style="
            text-align: center;
            display: block;
            width: 80px;
            height: 35px;
            line-height: 35px;
          "
          @click="selectAll('site')"
        >{{ $t('title.OrderAll') }}</span>
        <div v-for="item in siteData" :key="item.id">
          <span
            :class="{ active: form.site === item.siteCode }"
            style="
              text-align: center;
              display: block;
              width: 80px;
              height: 35px;
              line-height: 35px;
            "
            @click="selectTerrace('site', item)"
          >{{ item.siteCode }}</span>
        </div>
      </el-col>
    </el-row>
    <el-row style="margin: 20px 0">
      <template>
        <span>{{ $t('title.OrderType') }}:
          <el-radio v-model="form.orderType" label="" @change="_refresh()">{{
            $t('title.OrderAll')
          }}</el-radio>
          <el-radio v-model="form.orderType" label="0" @change="_refresh()">{{
            $t('title.primeorder')
          }}</el-radio>
          <el-radio v-model="form.orderType" label="1" @change="_refresh()">{{
            $t('title.Nonprimeorders')
          }}</el-radio>
        </span>
      </template>
    </el-row>
    <el-row v-if="form.platform.toLocaleUpperCase() === 'TIKTOK'" style="margin: 20px 0">
      <template>
        <span>{{ $t('title.deliveryAgeing') }}:
          <el-radio v-model="form._deliveryTime" label="" @change="_refresh()">{{
            $t('title.OrderAll')
          }}</el-radio>
          <el-radio v-model="form._deliveryTime" label="shipWithin24HoursOrLess" @change="_refresh()">ship with 24 hours or less</el-radio>
          <el-radio v-model="form._deliveryTime" label="autoCancelWithin24HoursOrLess" @change="_refresh()">auto-cancelling with 24 hours or less</el-radio>
          <el-radio v-model="form._deliveryTime" label="shippingOverdue" @change="_refresh()">shipping overdue</el-radio>
        </span>
      </template>
    </el-row>
    <el-form ref="form" :model="form" class="form" label-width="170px" style="display: flex">
      <div class="el-lt" style="width: 85%">
        <el-row :class="{ 'show-row': showRow }" class="row-input">
          <el-col :span="8">
            <el-form-item :label="$t('title.replenishmentNumber') + '/' + $t('title.platNumber') + ':'" label-width="145px">
              <el-input v-model="form.orderNumber" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('title.wmsOrderNumber') + ':'">
              <el-input v-model="form.wmsOrderNumber" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="SKU/FBM_SKU:">
              <el-input v-model="form.mulitSku" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('title.number')" label-width="145px">
              <el-select
                v-model="form.merchant"
                :placeholder="$t('page.selectPlaceholder')"
                clearable
                style="width:100%"
              >
                <el-option v-for="item in optionMerchant" :key="item.val" :label="item.code" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('order.Sourcenumber') + ':'" label-width="145px">
              <el-input v-model="form.sourceOrderNumber" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="24" class="mb-3">
          <el-col :span="24" style="display: flex; height: 36px">
            <!-- <el-button @click="handlerefresh">{{
              $t("title.Refresh")
            }}</el-button> -->
            <el-button :loading="exportLoading" @click="handleExport">
              {{ $t('page.export') }}
            </el-button>
            <el-button @click="showScreen">{{
              $t('title.filter')
            }}
            </el-button>
            <!--            批量转待调-->
            <el-button v-if="+status===8" type="primary" @click="handleBatchTransit">{{ $t('title.BatchTransit') }}</el-button>
            <!-- 批量作废 -->
            <el-button
              v-if="
                status === '0' ||
                  status === '7' ||
                  status === '8' ||
                  status === '2' ||
                  status === '6' ||
                  status === '100'
              "
              type="primary"
              @click="Batchvoid"
            >{{ $t('title.Batchvoid') }}
            </el-button>
            <!-- 批量分配 -->
            <el-button
              v-if="(params.statusList && params.statusList.length !== options.length) || status === '0' || status === '8' || status === '10' || status === '3' "
              type="primary"
              @click="Batchallocation"
            >
              {{ $t('title.Batchallocation') }}
            </el-button>
            <!-- 批量分配仓库 -->
            <el-button
              v-if="(params.statusList && params.statusList.length === options.length)|| status === '8'"
              type="primary"
              @click="BatchDistributionWarehouse"
            >{{ $t('title.BatchDistributionWarehouse') }}
            </el-button>
            <el-button type="primary" @click="exportTrackingNumberDialogVisible = true">导出物流单号</el-button>
          </el-col>
        </el-row>
      </div>
      <el-row class="el-rt" style="width: 20%">
        <el-col class="row-center">
          <el-button type="primary" @click="queryClick()">{{
            $t('page.search')
          }}
          </el-button>
          <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
          <el-button type="text" @click="showRowClick()">{{ showRow ? $t('page.hide') : $t('page.expand') }}</el-button>
        </el-col>
      </el-row>
    </el-form>
    <!-- 筛选条件 -->
    <el-row v-if="temp.length">
      筛选条件：
      <el-tag
        v-for="(tag, index) in temp"
        :key="index"
        closable
        style="margin-right: 5px"
        @close="closeTag(tag, index)"
      >
        <!-- tag.name === '出货仓库' ? tag.value.join():  -->
        {{ tag.name }}:{{ tag.value }}
      </el-tag>
    </el-row>
    <table-select-action :quantity="sidvoList.length" @reset="handleTableSelectReset" />
    <!-- tab栏切换 -->

    <el-row class="row-bg tab" style="width: 100%; margin: 20px 0; background-color: #f9fafc">
      <el-col
        v-if="StatusGroup.length > 0"
        :span="24"
        style="
          width: 100%;
          display: flex;
          overflow-x: scroll;
          -webkit-overflow-scrolling: touch;
          white-space: nowrap;
          overflow-y: hidden;
          align-items: center;
        "
      >
        <span
          :class="{ active: params.statusList && params.statusList.length === statusList.length, }"
          style=" display: inline-block; height: 40px; line-height: 40px; text-align: center; cursor: pointer; padding: 0 10px; "
          @click="bbbb(params.statusList)"
        >
          <span v-if="language === 'en-US'">{{ 'All orders' }}</span><span v-else>{{ '全部' }}</span>
        </span>
        <div v-for="item in StatusGroup" :key="item.status" class="row-bg">
          <p
            style=" cursor: pointer; height: 40px; line-height: 40px; text-align: center; display: flex; justify-content: center; "
            @click="handleTabsClick(item)"
          >
            <span> <i style="text-align: center; width: 15px; display: inline-block">|</i></span> <span
              :class="{ active: params.status !== undefined && item.status + '' === status, }"
              style="padding-left: 10px"
            ><i style="display: inline-block" /><span v-if="language === 'en-US'">{{ item.enremark }}</span><span
              v-else
            >{{ item.remark }}</span> </span>
            <span
              :class="{ active: params.status !== undefined && item.status + '' === status, activeCount: params.status !== undefined && item.status + '' === status, }"
              style="color: red; padding-right: 10px"
            > {{ '(' + item.count + ')' }}</span>
          </p>
        </div>
        <div class="row">
          <span> <i style=" text-align: center; width: 5px; display: inline-block; padding: 0 8px 0 5px; ">|</i></span>
          <span
            :class="{ active: params.statusList && params.statusList.length === options.length, }"
            style=" display: inline-block; cursor: pointer; height: 40px; line-height: 40px; padding-left: 10px; "
            @click="aaaa(params.statusList)"
          >
            <span v-if="language === 'en-US'" slot="">{{ 'error orders' }}</span><span v-else>{{ '问题件' }}</span>
            <span
              :class="{ activeCount: params.statusList && params.statusList.length === options.length, }"
              style="color: red; padding-right: 10px"
            > {{ '(' + sum + ')' }}
            </span>
          </span>
          <el-select
            v-model="errorstatus"
            :placeholder="$t('page.selectTheErrors')"
            clearable
            @change="changeList"
            @clear="clear"
          >
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="language === 'en-US' ? item.enremark : item.remark"
              :value="item.status + ''"
            >
              <span style="float: left"><span v-if="language === 'en-US'">{{ item.enremark }}</span><span
                v-else
              >{{ item.remark }}</span></span>
              <span style=" float: right; color: red; font-size: 13px; margin-left: 15px;">{{ item.count }}</span>
            </el-option>
          </el-select>
          <!-- 库存不足子分类 8 库存不足-->
          <el-select
            v-if="errorstatus === '8'"
            v-model="notEnoughStockSubStatusList"
            :placeholder="$t('title.OutOfStockSubCategories')"
            multiple
            collapse-tags
            clearable
            @change="changeList"
            @clear="clear"
          >
            <el-option v-for="(item,index) in InsufficientInventoryOptions" :key="index" :label="item.label" :value="item.value" />
          </el-select>
        </div>
      </el-col>
      <div v-if="isOrderAgin" style="width:100%; padding: 15px 0px;  white-space: nowrap; overflow-y: hidden;">
        <span
          :class="{ 'actives': aginStatus === ''}"
          class="row-bg"
          style="display: inline-block;padding:2px 10px; margin-left:10px;cursor:pointer"
          @click="selectStatus('')"
        >  <span v-if="language === 'en-US'">{{ 'All orders' }}{{ '(' + allcount + ')' }}</span><span v-else>{{
          '全部'
        }}{{ '(' + allcount + ')' }}</span> </span>

        <span
          v-for="(item,index) in aginList"
          :key="index"
          class="row-bg"
          style="cursor:pointer;"
          @click="selectStatus(item)"
        >
          <span> <i style="text-align: center;width:20px;display: inline-block">|</i></span>
          <span :class="{ 'active': aginStatus === item.status }" style="padding:5px 0px 5px 10px;"> <span
            v-if="language === 'en-US'"
          >{{ item.enremark }}</span><span v-else>{{ item.remark }}</span> <span
            :class="{ 'activeCount': aginStatus === item.status }"
            style="padding:5px 10px 5px 0;color:red"
          > {{ '(' + item.count + ')' }}</span></span>

        </span>
      </div>
    </el-row>
    <TableInfo
      ref="table"
      :platform="form.platform"
      :table-data="tableData"
      :table-height="tableHeight"
      :table-loading="tableLoading"
      site-code="form.siteCode"
      @getMul="getMultiple"
      @refresh="_refresh()"
    />

    <el-pagination
      :current-page="pager.current"
      :page-size="pager.size"
      :page-sizes="[20, 40, 100, 200, 300]"
      :total="pager.total"
      layout="total, sizes, prev, pager, next, jumper"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!-- 筛选弹框 -->
    <el-dialog
      :title="$t('title.filter')"
      :visible.sync="dialogFormVisiblefilter"
      icon="el-icon-circle-plus-outline"
      width="65%"
    >
      <el-form ref="filterForm" :model="filterForm" label-width="170px">
        <el-form-item :label="$t('title.platNumber')">
          <el-input
            v-model="filterForm.orderNumbersStr"
            :placeholder="$t('title.singleenter')"
            type="textarea"
            @change="checkOrderNumbersStr()"
          />
        </el-form-item>
        <el-form-item :label="$t('title.wmsOrderNumber')">
          <el-input
            v-model="filterForm.wmsOrderNumbersStr"
            :placeholder="$t('title.singleenter')"
            type="textarea"
            @change="checkOrderNumbersStr()"
          />
        </el-form-item>
        <el-form-item label="ItemIDS">
          <el-input
            v-model="filterForm.itemIdsStr"
            :placeholder="$t('title.EnterItemID')"
            type="textarea"
            @change="checkOrderNumbersStr()"
          />
        </el-form-item>
        <el-form-item :label="$t('title.shipmentnumber')">
          <el-input v-model="filterForm.trackingNumber" :placeholder="$t('page.inputPlaceholder')" />
        </el-form-item>
        <el-form-item :label="$t('title.Buyername')">
          <el-input v-model="filterForm.buyerName" :placeholder="$t('page.inputPlaceholder')" />
        </el-form-item>
        <el-form-item :label="$t('title.BuyerEmail')">
          <el-input v-model="filterForm.buyerEmail" :placeholder="$t('page.inputPlaceholder')" />
          <!-- 新增style -->
        </el-form-item>
        <el-form-item label="style">
          <el-select
            v-model="filterForm.style"
            :placeholder="$t('page.selectPlaceholder')"
            clearable
            filterable
            @visible-change="_queryStyleList"
          >
            <el-option v-for="item in styleOptions" :key="item.id" :label="item.styleName" :value="item.styleName" />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('title.orderamount')">
          <el-input
            v-model="filterForm.orderPriceStart"
            :placeholder="$t('page.inputPlaceholder')"
            style="width: 120px; padding-right: 10px"
          />
          至
          <el-input
            v-model="filterForm.orderPriceEnd"
            :placeholder="$t('page.inputPlaceholder')"
            style="width: 120px; padding-left: 10px"
          />
        </el-form-item>
        <el-form-item :label="$t('title.Shippingaddress')">
          <el-input
            v-model="filterForm.countryCode"
            :placeholder="$t('tips.entercountry')"
            style="width: 120px; padding-right: 10px"
          />
          <el-input
            v-model="filterForm.city"
            :placeholder="$t('tips.entercity')"
            style="width: 120px; padding-right: 10px"
          />
          <el-input v-model="filterForm.stateorregion" :placeholder="$t('tips.enterdistrict')" style="width: 120px" />
        </el-form-item>
        <!-- 系统创建时间 -->
        <el-form-item :label="$t('page.systemcreatetime')">
          <el-date-picker
            v-model="filterForm.createDateStart"
            :placeholder="$t('title.starttime')"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
          />
          {{ $t('title.to') }}
          <el-date-picker
            v-model="filterForm.createDateEnd"
            :placeholder="$t('title.endtime')"
            default-time="23:59:59"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
          />
        </el-form-item>
        <!-- 平台付款时间 -->
        <el-form-item :label="$t('title.platformpaymenttime')">
          <el-date-picker
            v-model="filterForm.paymentsDateStart"
            :placeholder="$t('title.starttime')"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
          />
          {{ $t('title.to') }}
          <el-date-picker
            v-model="filterForm.paymentsDateEnd"
            :placeholder="$t('title.endtime')"
            default-time="23:59:59"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
          />
        </el-form-item>
        <!-- 平台创建时间 -->
        <el-form-item :label="$t('page.platformcreatetime')">
          <el-date-picker
            v-model="filterForm.orderCreateDateStart"
            :placeholder="$t('title.starttime')"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
          />
          {{ $t('title.to') }}
          <el-date-picker
            v-model="filterForm.orderCreateDateEnd"
            :placeholder="$t('title.endtime')"
            default-time="23:59:59"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
          />
        </el-form-item>
        <el-form-item :label="$t('title.Deliverytime')">
          <el-date-picker
            v-model="filterForm.sendDateStart"
            :placeholder="$t('title.starttime')"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
          />
          {{ $t('title.to') }}
          <el-date-picker
            v-model="filterForm.sendDateEnd"
            :placeholder="$t('title.endtime')"
            default-time="23:59:59"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
          />
        </el-form-item>
        <el-form-item :label="$t('title.ShippingWarehouse')">
          <el-select v-model="filterForm.warehouseCodesStr" :placeholder="$t('page.selectPlaceholder')" multiple>
            <el-option
              v-for="item in warehouseOptions"
              :key="item.id"
              :label="item.code"
              :value="item.value"
            />
          </el-select>
        </el-form-item>

        <el-row class="grid-content" justify="center" type="flex">
          <el-button @click="dialogFormVisiblefilter = false">{{
            $t('title.cancel')
          }}
          </el-button>
          <el-button type="primary" @click="handlefilter()">{{
            $t('title.filter')
          }}
          </el-button>
        </el-row>
      </el-form>
    </el-dialog>
    <!-- 确认弹框 -->
    <el-dialog :title="this.$t('page.Prompt')" :visible.sync="alloatedialogVisibleLoading" width="30%">
      <span>{{ this.$t('title.allocateorders') }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="alloatedialogVisibleLoading = false">{{
          $t('title.cancel')
        }}</el-button>
        <el-button :loading="alloateSureLoading" type="primary" @click="alloatedialogVisibleSure">{{
          $t('title.confirm')
        }}</el-button>
      </span>
    </el-dialog>
    <!-- 批量分配弹框 -->
    <el-dialog :visible.sync="alloatedialogVisible" width="30%">
      <span>{{ arr.toString() + this.$t('title.cannotallocation') }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="alloatedialogVisible = false">{{
          $t('title.cancel')
        }}</el-button>
        <el-button :loading="alloateSureLoading" type="primary" @click="alloateVisibleSure">{{
          $t('title.confirm')
        }}</el-button>
      </span>
    </el-dialog>
    <!--导出物流单号-->
    <export-tracking-number-dialog :visible.sync="exportTrackingNumberDialogVisible" />
  </div>
</template>
<script>
// import oms_base from '@/api/a-base-host'
import TableSelectAction from '@/components/TableSelectAction'
import TableInfo from './components/TableInfo'
import {
  allocationorders,
  batchHandSplitWarehouse,
  batchTransit,
  bleadToFbaForOmsOrder,
  cancelorders,
  getSelectItems,
  getSelectItemsForWareHouseList,
  handSplitWarehouse,
  listOmsSelfDeliveryOrderByPage,
  listOmsSelfDeliveryOrderByPageStatusGroup,
  querySiteList,
  queryStyleList,
  queryTerraceList
} from '@/api/omsorder'
import { deepClone, exportSuccess } from '@/utils'
import Cookies from 'js-cookie'
import mixin from '@/mixin/oms-mixin'
import { unifiedExport } from '@/api/a-base-host'
import { Status_Enum, Status_Detail_Enum, InsufficientInventoryOptions } from './enum'
import ExportTrackingNumberDialog from './components/ExportTrackingNumberDialog.vue'

export default {
  components: { ExportTrackingNumberDialog, TableSelectAction, TableInfo },
  mixins: [mixin],
  data() {
    return {
      exportLoading: false,
      aginList: [],
      // aginList: [
      //   {
      //     remark: '今日待发',
      //     status: 1
      //   },
      //   {
      //     remark: '发货超时',
      //     status: 2
      //   }
      // ],
      warehouseOptions: [],
      loading: '',
      isOrderAgin: true,
      batchArr: [],
      language: '',
      arr: [],
      aaa: false,
      alloatedialogVisibleLoading: false,
      alloateSureLoading: false,
      alloatedialogVisible: false,
      exportTrackingNumberDialogVisible: false,
      handsyncordersLoading: false,
      params: {},
      detaildatas: [],
      tab: {},
      tabIndex: '',
      options: [],
      flag: '',
      errorstatus: '',
      notEnoughStockSubStatusList: [],
      status: '0',
      errorList: [],
      statusList: [],
      sum: 0,
      StatusGroup: [],
      styleOptions: [],
      tableData: [],
      tableLoading: false,
      tableHeight: 400,
      cancelorgobackDialogVisible: false,
      platform: '',
      sites: [],
      detailDatas: [],
      show: false,
      dialogFormVisiblefilter: false,
      showRow: false, // 切换显示input
      tableDatas: [],
      terraceData: [],
      siteData: [],
      stockAreaTableExpand: [],
      pager: {
        size: 20,
        current: 1,
        total: 0
      },
      aginStatus: '',
      optionMerchant: [],
      form: {
        platform: 'Amazon',
        site: 'US',
        mulitSku: '',
        orderType: '',
        orderNumber: '',
        merchant: '',
        sellPlatId: '',
        wmsOrderNumber: '',
        siteId: '',
        descs: 'create_date',
        sourceOrderNumber: '',
        _deliveryTime: ''
      },
      filterForm: {
        warehouseCodesStr: [],
        orderNumbersStr: '',
        wmsOrderNumbersStr: '',
        itemIdsStr: '',
        trackingNumber: '',
        buyerName: '',
        buyerEmail: '',
        style: '',
        orderPriceStart: '',
        orderPriceEnd: '',
        countryCode: '',
        city: '',
        stateorregion: '',
        createDateStart: '',
        createDateEnd: '',
        paymentsDateStart: '',
        paymentsDateEnd: '',
        orderCreateDateStart: '',
        orderCreateDateEnd: '',
        sendDateStart: '',
        sendDateEnd: ''
      },
      allcount: 0,
      temp: [],
      itemSplitListAllocation: [],
      sidvoList: [],
      expands: [],
      // 获取row的key值
      getRowKeys: (row) => {
        return row.id
      }
    }
  },
  computed: {
    InsufficientInventoryOptions() {
      return InsufficientInventoryOptions
    },
    exportUrl() {
      const {
        descs,
        platform,
        site,
        mulitSku,
        orderType,
        wmsOrderNumber,
        orderNumber,
        merchant,
        sourceOrderNumber
      } = this.form
      const {
        warehouseCodesStr,
        orderNumbersStr,
        wmsOrderNumbersStr,
        itemIdsStr,
        trackingNumber,
        buyerName,
        buyerEmail,
        style,
        orderPriceStart,
        orderPriceEnd,
        countryCode,
        city,
        stateorregion,
        createDateStart,
        createDateEnd,
        paymentsDateStart,
        paymentsDateEnd,
        orderCreateDateStart,
        orderCreateDateEnd,
        sendDateStart,
        sendDateEnd
      } = this.filterForm

      return (
        `http://oms.test.wangoon.cn/oms/self-delivery-order/exportOmsSelfDeliveryOrder?descs=${descs}&platform=${platform}&site=${site}&mulitSku=${mulitSku}&status=${Number(
          this.status
        )}&orderType=${orderType}&orderNumber=${orderNumber}&merchant=${merchant}&status=${status}&wmsOrderNumber=${wmsOrderNumber}&orderNumbersStr=${orderNumbersStr}&wmsOrderNumbersStr=${wmsOrderNumbersStr}&itemIdsStr=${itemIdsStr}&trackingNumber=${trackingNumber}&buyerName=${buyerName}&buyerEmail=${buyerEmail}&style=${style}
        &orderPriceStart=${orderPriceStart}&orderPriceEnd=${orderPriceEnd}&countryCode=${countryCode}&city=${city}&stateorregion=${stateorregion}&createDateStart=${createDateStart}&createDateEnd=${createDateEnd}&paymentsDateStart=${paymentsDateStart}&paymentsDateEnd=${paymentsDateEnd}&orderCreateDateStart=${orderCreateDateStart}&orderCreateDateEnd=${orderCreateDateEnd}
        &sendDateStart=${sendDateStart}&sendDateEnd=${sendDateEnd}&warehouseCodesStr=${warehouseCodesStr.toString()}&sourceOrderNumber=${sourceOrderNumber}`
      )
    },
    // 生成对应的请求参数
    queryParameter() {
      this.warehouseStr()
      const filterObj = deepClone(this.filterForm)
      for (const key in filterObj) {
        if (filterObj[key] === '' || filterObj[key] === null) {
          delete filterObj[key]
        }
      }
      return Object.assign(
        {},
        this.pager,
        this.form,
        { status: Number(this.status) },
        filterObj,
        this.getDynamicParams
      )
    },
    queryAllParameter() {
      this.warehouseStr()
      const filterObj = deepClone(this.filterForm)
      // filterObj.warehouseCodesStr = filterObj.warehouseCodesStr.toString()
      console.log(filterObj)
      for (const key in filterObj) {
        if (filterObj[key] === '' || filterObj[key] === null) {
          delete filterObj[key]
        }
      }
      return Object.assign(
        {},
        this.pager,
        this.form,
        filterObj,
        {
          statusList: this.statusList
        },
        this.getDynamicParams
      )
    },
    selectAllErrorParameter() {
      this.warehouseStr()
      const filterObj = deepClone(this.filterForm)
      for (const key in filterObj) {
        if (filterObj[key] === '' || filterObj[key] === null) {
          delete filterObj[key]
        }
      }
      return Object.assign(
        {},
        this.pager,
        this.form,
        filterObj,
        {
          statusList: this.errorList
        },
        this.getDynamicParams
      )
    },
    // const filterObj = deepClone(this.filterForm)
    //   for (const key in filterObj) {
    //     if (filterObj[key] !== 0 || filterObj[key] === '' || filterObj[key] === null) {
    //       delete filterObj[key]
    //     }
    //   }
    queryErrorStatusParameter() {
      this.warehouseStr()
      const filterObj = deepClone(this.filterForm)
      for (const key in filterObj) {
        if (filterObj[key] === '' || filterObj[key] === null) {
          delete filterObj[key]
        }
      }
      const {
        platform,
        site,
        mulitSku,
        orderType,
        orderNumber,
        merchant,
        sellPlatId,
        siteId,
        descs,
        wmsOrderNumber,
        sourceOrderNumber
      } = this.form
      return Object.assign({}, { statusList: this.errorList },
        { platform, site, mulitSku, orderType, orderNumber, merchant, sellPlatId, siteId, descs, wmsOrderNumber, sourceOrderNumber },
        filterObj
      )
    },
    queryAllStatusParameter() {
      this.warehouseStr()
      const filterObj = deepClone(this.filterForm)
      for (const key in filterObj) {
        if (filterObj[key] === '' || filterObj[key] === null) {
          delete filterObj[key]
        }
      }
      const {
        platform,
        site,
        mulitSku,
        orderType,
        orderNumber,
        merchant,
        sellPlatId,
        siteId,
        descs,
        wmsOrderNumber,
        sourceOrderNumber
      } = this.form
      return Object.assign(
        {},
        {
          statusList: this.statusList
        },
        {
          platform,
          site,
          mulitSku,
          orderType,
          orderNumber,
          merchant,
          sellPlatId,
          siteId,
          descs,
          wmsOrderNumber,
          sourceOrderNumber
        },
        filterObj
      )
    },
    queryStatusParameter() {
      this.warehouseStr()
      const filterObj = deepClone(this.filterForm)

      for (const key in filterObj) {
        if (filterObj[key] === '' || filterObj[key] === null) {
          delete filterObj[key]
        }
      }
      const {
        platform,
        site,
        mulitSku,
        orderType,
        orderNumber,
        merchant,
        sellPlatId,
        siteId,
        descs,
        wmsOrderNumber,
        sourceOrderNumber
      } = this.form
      return Object.assign(
        {},
        { status: Number(this.status) },
        {
          platform,
          site,
          mulitSku,
          orderType,
          orderNumber,
          merchant,
          sellPlatId,
          siteId,
          descs,
          wmsOrderNumber,
          sourceOrderNumber
        },
        filterObj,
        this.getDynamicParams
      )
    },
    getDynamicParams() {
      const newParams = {}
      if (this.form.platform.toLocaleUpperCase() === 'TIKTOK') {
        // tiktok 发货时效
        const deliveryTimeEnum = {
          shipWithin24HoursOrLess: 'shipWithin24HoursOrLess',
          autoCancelWithin24HoursOrLess: 'autoCancelWithin24HoursOrLess',
          shippingOverdue: 'shippingOverdue'
        }

        const deliveryTimeKey = deliveryTimeEnum[this.form._deliveryTime]

        if (deliveryTimeKey) {
          newParams[deliveryTimeKey] = true
        }
      }

      // 库存不足子分类
      if (this.status === '8' && this.notEnoughStockSubStatusList.length > 0) {
        newParams.notEnoughStockSubStatusList = this.notEnoughStockSubStatusList
      }

      return {
        ...newParams
      }
    }
  },
  watch: {
    tableData(val) {
      console.log(val)
    }
  },
  mounted() {
    this.language = Cookies.get('language') === 'en' ? 'en-US' : 'zh-CN'
    this._queryTerraceList()
    this._listOmsSelfDeliveryOrderByPage(this.queryParameter)
    this._getSelectItemsForWareHouseList()
  },
  methods: {
    warehouseStr() {
      this.filterForm.warehouseCodesStr = this.filterForm.warehouseCodesStr.toString()
    },
    // changeCheckWay(item) {
    //   const warehouseCodes = item
    //   console.log(item)
    //   warehouseCodes.map(e => {
    //     console.log(e.value)
    //     this.ruleForm.warehouseCodesStr = e.value
    //   })
    //   console.log(this.ruleForm.warehouseCodesStr)
    // },
    // 出货仓库
    async _getSelectItemsForWareHouseList(query) {
      const { datas } = await getSelectItemsForWareHouseList(query)
      this.warehouseOptions = datas
    },
    BatchDistributionWarehouse() {
      this.batchArr = []
      if (this.sidvoList.length === 0) {
        this.$message.error(this.$t('title.select'))
        return
      }
      this.sidvoList = this.sidvoList.filter(item => item.status === 8)
      if (!this.sidvoList.length) {
        this.$message.error('无符合条件的订单，请核对！')
        return
      }
      this.sidvoList.map(item => {
        const { id, platform, site, merchant, orderNumber, status } = item
        const itemSplitList = item.itemSplitList.filter(item => item.status === 9)
        itemSplitList.length ? this.batchArr.push({
          inventoryAllocationType: 0,
          id,
          platform,
          site,
          merchant,
          orderNumber,
          status,
          itemSplitListAllocation: itemSplitList
        }) : ''
      })
      if (this.batchArr.every(item => !item.itemSplitListAllocation.length)) {
        this.$message.error('无符合条件的订单，请核对！')
        return
      }
      // console.log(this.batchArr)
      this.$confirm(
        this.$t('title.allocateorders'),
        this.$t('page.Prompt'),
        {
          confirmButtonText: this.$t('title.confirm'),
          cancelButtonText: this.$t('title.cancel'),
          type: 'warning'
        }
      ).then(() => {
        this._batchHandSplitWarehouse(this.batchArr)
      })
    },
    async _batchHandSplitWarehouse(arr) {
      try {
        this.loading = this.$loading({
          lock: true,
          fullscreen: true,
          text: '等待中',
          background: 'rgba(0, 0, 0, 0.8)'
        })
        const { datas, msg } = await batchHandSplitWarehouse(arr)
        datas ? this.$message.success({
          dangerouslyUseHTMLString: true,
          message: datas.replace(/\r\n/g, '<br/>'),
          showClose: true,
          duration: 1000 * 10
        }) : this.$message.error(msg)
        this._refresh()
      } catch (err) {
        console.log(err)
      } finally {
        this.loading.close()
      }
    },
    closeTag(tag, index) {
      this.temp.splice(index, 1)
      const { key } = tag
      for (var i in this.filterForm) {
        // if (i === key) delete this.filterForm[i]
        if (i === key) this.filterForm[i] = ''
      }
      for (var I in this.queryStatusParameter) {
        // if (I === key) delete this.queryStatusParameter[i]
        if (I === key) this.queryStatusParameter[i] = ''
      }
      this.$forceUpdate()
      this._refresh()
    },
    _refresh() {
      this.allcount = 0
      const statusList = this.params.statusList || []
      if (statusList.length === this.options.length) {
        this._listOmsSelfDeliveryOrderByPage(this.selectAllErrorParameter, 0, 5)
        this.aginStatus = ''
        let obj = {}
        obj = deepClone(this.selectAllErrorParameter)
        delete obj.descs
        delete obj.orderAging
        this._listOmsSelfDeliveryOrderByPageStatusGroup(obj)
      } else if (statusList.length === this.statusList.length) {
        this._listOmsSelfDeliveryOrderByPage(this.queryAllParameter, 0, 5)
        this.aginStatus = ''
        let obj = {}
        obj = deepClone(this.queryAllParameter)
        delete obj.descs
        delete obj.orderAging
        this._listOmsSelfDeliveryOrderByPageStatusGroup(obj)
      } else {
        console.log(this.queryParameter)
        this._listOmsSelfDeliveryOrderByPage(this.queryParameter, 0, 5)
        this.aginStatus = ''
        let obj = {}
        obj = deepClone(this.queryParameter)
        delete obj.descs
        delete obj.orderAging
        this._listOmsSelfDeliveryOrderByPageStatusGroup(obj)
      }
    },
    clear() {
      this.status = '0'
      this.form.orderNumber = ''
      this.form.mulitSku = ''
      this._listOmsSelfDeliveryOrderByPage(this.queryParameter)
    },

    // 获取style数据
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    // 点击展开控制文本框的显示隐藏
    showRowClick() {
      this.showRow = !this.showRow
    },
    // 平台条件查询
    async _queryTerraceList() {
      const { datas } = await queryTerraceList()
      const platformCodeList = ['amazon', 'ebay', 'walmart', 'Shopify', 'KOL', 'standwebsite', 'nordstrom', 'Nordstrom Rack', 'Shein', 'QVC', 'Nordstrom', 'TikTok', 'Tik Tok', 'Saks-Off-5th', 'Shein-QTG', 'Temu-QTG', 'Temu', 'Shein-BTG', 'JustFab']
      this.terraceData = datas?.filter(e => {
        if (e.platformShortName === 'Amazon') {
          this.form.sellPlatId = e.id
          this.sellPlatName = e.platformShortName
          this.form.platform = e.platformCode
        }
        const index = platformCodeList.indexOf(e.platformCode)
        if (index > -1) {
          platformCodeList.splice(index, 1)
          return true
        }
      })
      this._querySiteList({ sellPlatformId: this.form.sellPlatId })
    },
    // 站点条件查询
    async _querySiteList(params, type) {
      const { datas } = await querySiteList(params, type)
      this.siteData = []
      datas.map((e) => {
        if (e.siteCode === 'US') {
          this.form.siteId = e.id
          this.form.site = e.siteCode
          // this.siteData.push(e)
        }
        // if (e.siteCode === 'US' || e.siteCode === 'MX' || e.siteCode === 'CA' || e.siteCode === 'GB') {
        //   this.siteData.push(e)
        // }
        if (['US', 'MX', 'CA', 'GB'].includes(e.siteCode)) {
          this.siteData.push(e)
        }
        if (this.form.sellPlatId === 18 && (e.siteCode === 'DE' || e.siteCode === 'FR')) {
          this.siteData.push(e)
        }
        // Shein
        if (this.form.sellPlatId === (process.env.NODE_ENV === 'production' ? 34 : 37) && e.siteCode !== 'US') {
          this.siteData.push(e)
        }
        if ((this.form.sellPlatId === (process.env.NODE_ENV === 'production' ? 29 : 32) && e.siteCode === 'EU')) {
          this.siteData.push(e)
        }
        if (this.form.sellPlatId === 17 && (e.siteCode === 'CN' || e.siteCode === 'EU')) {
          this.siteData.push(e)
        }
      })
      // 获取到平台和站点的值后,再获取SKU信息数据
      this.pager.current = 1
      this.aginStatus = ''
      const statusList = this.params.statusList || []
      if (type === 1) {
        if (statusList.length === this.options.length) {
          this._listOmsSelfDeliveryOrderByPage(this.selectAllErrorParameter, 0, 5)
          let obj = {}
          obj = deepClone(this.selectAllErrorParameter)
          delete obj.descs
          delete obj.orderAging
          this._listOmsSelfDeliveryOrderByPageStatusGroup(obj)
        } else if (statusList.length === this.statusList.length) {
          this._listOmsSelfDeliveryOrderByPage(this.queryAllParameter, 0, 5)
          let obj = {}
          obj = deepClone(this.queryAllParameter)
          delete obj.descs
          delete obj.orderAging
          this._listOmsSelfDeliveryOrderByPageStatusGroup(obj)
        } else {
          this._listOmsSelfDeliveryOrderByPage(this.queryParameter, 0, 5)
          let obj = {}
          obj = deepClone(this.queryParameter)
          delete obj.descs
          delete obj.orderAging
          this._listOmsSelfDeliveryOrderByPageStatusGroup(obj)
        }
      }
      // this._listOmsSelfDeliveryOrderByPage({
      //   platform: this.form.platform,
      //   site: this.form.site,
      //   descs: this.form.descs,
      //   size: this.pager.size,
      //   current: this.pager.current,
      //   total: this.pager.total,
      //   status: this.status,
      //   sku: this.form.sku,
      //   orderNumber: this.form.orderNumber
      // })
      this._getSelectItems({
        size: this.pager.size,
        current: this.pager.current,
        total: this.pager.total,
        platform: this.form.platform,
        site: this.form.site
      })
    },

    // 选择平台和站点
    selectTerrace(type, data) {
      this.allcount = 0
      if (type === 'terrace') {
        // 平台
        this.form.sellPlatId = data.id
        this.sellPlatName = data.platformShortName
        this.form.platform = data.platformCode

        // 获取当前选中平台下第一个站点
        for (let i = 0; i < this.siteData.length; i++) {
          if (this.siteData[i].sellPlatformId === this.form.sellPlatId) {
            this.form.siteId = this.siteData[i].id
            this.form.site = this.siteData[i].siteCode
            break
          }
        }
        this._querySiteList({ sellPlatformId: this.form.sellPlatId }, 1)
      } else {
        // 站点
        this.form.siteId = data.id
        this.form.site = data.siteCode
      }
      this.pager.current = 1
      this.aginStatus = ''
      const statusList = this.params.statusList || []
      if (statusList.length === this.options.length) {
        this._listOmsSelfDeliveryOrderByPage(this.selectAllErrorParameter, 0, 5)
        let obj = {}
        obj = deepClone(this.selectAllErrorParameter)
        delete obj.descs
        delete obj.orderAging
        this._listOmsSelfDeliveryOrderByPageStatusGroup(obj)
      } else if (statusList.length === this.statusList.length) {
        this._listOmsSelfDeliveryOrderByPage(this.queryAllParameter, 0, 5)
        let obj = {}
        obj = deepClone(this.queryAllParameter)
        delete obj.descs
        delete obj.orderAging
        this._listOmsSelfDeliveryOrderByPageStatusGroup(obj)
      } else {
        this._listOmsSelfDeliveryOrderByPage(this.queryParameter, 0, 5)
        let obj = {}
        obj = deepClone(this.queryParameter)
        delete obj.descs
        delete obj.orderAging
        this._listOmsSelfDeliveryOrderByPageStatusGroup(obj)
      }
      this._getSelectItems({
        platform: this.form.platform,
        site: this.form.site
      })
    },
    selectAll(type) {
      this.allcount = 0
      type === 'terrace' ? (this.form.platform = '') : (this.form.site = '')
      const statusList = this.params.statusList || []
      if (statusList.length === this.options.length) {
        this._listOmsSelfDeliveryOrderByPage(this.selectAllErrorParameter, 0, 5)
        this.aginStatus = ''
        let obj = {}
        obj = deepClone(this.selectAllErrorParameter)
        delete obj.descs
        delete obj.orderAging
        this._listOmsSelfDeliveryOrderByPageStatusGroup(obj)
      } else if (statusList.length === this.statusList.length) {
        this._listOmsSelfDeliveryOrderByPage(this.queryAllParameter, 0, 5)
        this.aginStatus = ''
        let obj = {}
        obj = deepClone(this.queryAllParameter)
        delete obj.descs
        delete obj.orderAging
        this._listOmsSelfDeliveryOrderByPageStatusGroup(obj)
      } else {
        // 选中平台的全部时候 调用列表的接口  通过传参来控是否在列表内调用获取状态的接口
        // this.queryParameter
        this._listOmsSelfDeliveryOrderByPage(this.queryParameter, 0, 5)
        this.aginStatus = ''
        let obj = {}
        obj = deepClone(this.queryParameter)
        delete obj.descs
        delete obj.orderAging
        // 获取tab栏状态
        this._listOmsSelfDeliveryOrderByPageStatusGroup(obj)
      }
    },
    // 点击查询条件获取信息
    queryClick(query) {
      this.pager.current = 1
      this.form.platform === 'ebay' ? (this.show = true) : (this.show = false)
      if (this.form.orderNumber !== '' || this.form.mulitSku !== '' || this.form.wmsOrderNumber !== '' || this.form.merchant !== '') {
        this.errorstatus = ''
        this._listOmsSelfDeliveryOrderByPage(this.queryAllParameter)
      } else {
        this._refresh()
      }
    },
    // 点击重置清空文本框信息
    resetClick(val) {
      this.temp = []
      this.filterForm = this.$options.data().filterForm
      // this.filterForm = {}
      this.form.merchant = ''
      this.form.mulitSku = ''
      this.form.orderNumber = ''
      this.form.wmsOrderNumber = ''
      this.errorstatus = ''
      this.status = '0'
      this.aginStatus = ''
      this.form.sourceOrderNumber = ''
      this._listOmsSelfDeliveryOrderByPage(this.queryParameter)
      if (this.status === '0') {
        this.isOrderAgin = true
        this.allcount = 0
      }
    },
    checkOrderNumbersStr() {
      var str = /,$/gi
      this.filterForm.orderNumbersStr = this.filterForm.orderNumbersStr
        ? this.filterForm.orderNumbersStr.replace(str, '')
        : this.filterForm.orderNumbersStr
      console.log(this.filterForm.orderNumbersStr)
      this.$forceUpdate()
      // $或者^(\d+,)*\d+$
    },

    // 筛选
    handlefilter() {
      this.pager.current = 1
      this.errorstatus = ''
      this._listOmsSelfDeliveryOrderByPage(this.queryAllParameter, 0, 5)
      let objct = {}
      objct = deepClone(this.queryAllParameter)
      delete objct.descs
      this._listOmsSelfDeliveryOrderByPageStatusGroup(objct)
      this.filterForm.warehouseCodesStr = this.filterForm.warehouseCodesStr.split(',')
      const obj = deepClone(this.filterForm)
      const list = []
      for (var key in obj) {
        var temp = {}
        temp.key = key
        temp.name = this.$t(`${key}`)
        key === 'warehouseCodesStr' ? obj[key] = obj[key].join() : ''
        temp.value = obj[key]
        list.push(temp)
        this.temp = list.filter(item => item.value !== '' && item.value !== undefined && item.value !== null)
      }
      // && item.value.length !== 0
      // this.filterForm = this.$options.data().filterForm
      this.dialogFormVisiblefilter = false
    },
    // 刷新页面
    handlerefresh() {
      this.pager.current = 1
      this.dialogFormVisiblefilter = false
      this._refresh()
      this.form.sku = ''
      this.form.orderNumber = ''
    },
    // 获取账号
    async _getSelectItems(data) {
      const { datas } = await getSelectItems(data)
      this.optionMerchant = datas
    },
    async _listOmsSelfDeliveryOrderByPage(params, type, isStatus) {
      console.log(params, type, isStatus)
      type && type === 1 ? '' : this.pager.current = 1
      this.params = params
      try {
        this.tableLoading = true
        const {
          datas: { pager, records }
        } = await listOmsSelfDeliveryOrderByPage(params)
        this.tableData = records
        this.pager = pager
        if (params.statusList && params.statusList.length === this.statusList.length) {
          this.isOrderAgin = false
        }
        if (this.form.mulitSku || this.form.orderNumber) {
          const { status } =
          this.tableData.find(
            (item) =>
              item.mulitSku === this.form.mulitSku ||
              item.orderNumber === this.form.orderNumber
          ) || []
          if (status) {
            const STATUS = status.toString()
            console.log(STATUS)
            if (['7', '8', '10', '100', '101', '999', '104', '105'].includes(STATUS)) {
              this.errorstatus = STATUS
            }
            this.status = STATUS
          }
        }
        if (params.status && [7, 8, 10, 100, 101, 999, 104, 105].includes(params.status)) {
          this.errorstatus = params.status + ''
        } else {
          this.errorstatus = ''
        }
        if (!isStatus) {
          let obj = {}
          obj = deepClone(this.queryStatusParameter)
          delete obj.descs
          this._listOmsSelfDeliveryOrderByPageStatusGroup(obj)
        }
      } finally {
        this.tableLoading = false
      }
    },
    changeList() {
      this.allcount = 0
      if (['7', '8', '10', '100', '101', '999', '104', '105'].includes(this.errorstatus)) {
        this.isOrderAgin = true
        this.aginStatus = ''
      } else {
        this.isOrderAgin = false
      }
      this.status = this.errorstatus + ''
      this.pager.current = 1
      const obj = Object.assign(
        {},
        this.pager,
        this.form,
        { status: Number(this.status) },
        this.filterForm,
        this.getDynamicParams
      )
      this._listOmsSelfDeliveryOrderByPage(obj, 0, 5)
      let objct = {}
      objct = deepClone(this.queryStatusParameter)
      delete obj.descs
      this._listOmsSelfDeliveryOrderByPageStatusGroup(objct)
    },
    async _listOmsSelfDeliveryOrderByPageStatusGroup(data) {
      try {
        this.tableLoading = true
        // let obj = {}
        // obj = deepClone(this.queryStatusParameter)
        // delete obj.descs
        const { datas } = await listOmsSelfDeliveryOrderByPageStatusGroup(data)
        this.allcount = 0
        datas.order_aging.map(item => {
          this.allcount += Number(item.count)
        })
        this.aginList = datas.order_aging

        // datas做翻译
        for (var i = 0; i < datas.status.length; i++) {
          datas.status[i].enremark = Status_Enum[datas.status[i].status]
        }

        for (var j = 0; j < datas.order_aging.length; j++) {
          datas.order_aging[j].enremark = Status_Detail_Enum[datas.order_aging[j].status]
        }

        this.statusList = datas.status.filter(
          (item) =>
            item.status !== 201 || item.status !== 102 || item.status !== 103
        )
        // 0 1  2 6 7 8  10  101  100 999
        // || e.status === 1 || e.status === 2 || e.status === 6 || e.status === 7 || e.status === 8 || e.status === 10 || e.status === 101 || e.status === 100 || e.status === 999

        this.statusList = this.statusList.map((item) => item.status)
        let StatusGroup = deepClone(datas.status)
        StatusGroup = StatusGroup.filter(
          (item) =>
            item.status === 0 ||
            item.status === 1 ||
            item.status === 2 ||
            item.status === 3 ||
            item.status === 6 ||
            item.status === 4 ||
            item.status === 98 ||
            item.status === 97 ||
            item.status === 99 ||
            item.status === 301
        )

        this.StatusGroup = StatusGroup

        this.options = deepClone(datas.status)
        this.options = this.options.filter(
          (item) =>
            item.status === 7 ||
            item.status === 8 ||
            item.status === 100 ||
            item.status === 101 ||
            item.status === 10 ||
            item.status === 999 ||
            item.status === 104 ||
            item.status === 105
        )
        this.sum = 0
        this.options.map((item) => {
          this.sum += item.count
        })
        this.errorList = this.options.map((item) => item.status)
      } finally {
        this.tableLoading = false
      }
    },
    // // 展开详情
    async handleexpand(row, expandedRows) {
      console.log(row, expandedRows)
    },
    // 导出文件
    async handleExport() {
      const statusList = this.params.statusList || []
      if (statusList.length === this.options.length) {
        const { platform, site, mulitSku, orderType, wmsOrderNumber, orderNumber, merchant, sourceOrderNumber } = this.form
        const {
          warehouseCodesStr,
          orderNumbersStr,
          wmsOrderNumbersStr,
          itemIdsStr,
          trackingNumber,
          buyerName,
          buyerEmail,
          style,
          orderPriceStart,
          orderPriceEnd,
          countryCode,
          city,
          stateorregion,
          createDateStart,
          createDateEnd,
          paymentsDateStart,
          paymentsDateEnd,
          orderCreateDateStart,
          orderCreateDateEnd,
          sendDateStart,
          sendDateEnd
        } = this.filterForm
        const params = {
          platform,
          site,
          mulitSku,
          statusList: this.errorList,
          orderType,
          orderNumber,
          merchant,
          wmsOrderNumber,
          orderNumbersStr,
          wmsOrderNumbersStr,
          itemIdsStr,
          trackingNumber,
          buyerName,
          buyerEmail,
          style,
          orderPriceStart,
          orderPriceEnd,
          countryCode,
          city,
          stateorregion,
          createDateStart,
          createDateEnd,
          paymentsDateStart,
          paymentsDateEnd,
          orderCreateDateStart,
          orderCreateDateEnd,
          sendDateStart,
          sendDateEnd,
          warehouseCodesStr: warehouseCodesStr.toString(),
          sourceOrderNumber,
          ...this.getDynamicParams
        }
        await unifiedExport({
          appName: 'oms',
          exportType: 'self-delivery-order',
          reqParam: JSON.stringify(params)
        }).finally(() => {
          this.exportLoading = false
        })
        exportSuccess()
      } else if (statusList.length === this.statusList.length) {
        const { platform, site, mulitSku, orderType, wmsOrderNumber, orderNumber, merchant, sourceOrderNumber } = this.form
        const {
          warehouseCodesStr,
          orderNumbersStr,
          wmsOrderNumbersStr,
          itemIdsStr,
          trackingNumber,
          buyerName,
          buyerEmail,
          style,
          orderPriceStart,
          orderPriceEnd,
          countryCode,
          city,
          stateorregion,
          createDateStart,
          createDateEnd,
          paymentsDateStart,
          paymentsDateEnd,
          orderCreateDateStart,
          orderCreateDateEnd,
          sendDateStart,
          sendDateEnd
        } = this.filterForm
        const params = {
          platform,
          site,
          mulitSku,
          statusList: this.statusList,
          orderType,
          orderNumber,
          merchant,
          wmsOrderNumber,
          orderNumbersStr,
          wmsOrderNumbersStr,
          itemIdsStr,
          trackingNumber,
          buyerName,
          buyerEmail,
          style,
          orderPriceStart,
          orderPriceEnd,
          countryCode,
          city,
          stateorregion,
          createDateStart,
          createDateEnd,
          paymentsDateStart,
          paymentsDateEnd,
          orderCreateDateStart,
          orderCreateDateEnd,
          sendDateStart,
          sendDateEnd,
          warehouseCodesStr: warehouseCodesStr.toString(),
          sourceOrderNumber,
          ...this.getDynamicParams
        }
        await unifiedExport({
          appName: 'oms',
          exportType: 'self-delivery-order',
          reqParam: JSON.stringify(params)
        }).finally(() => {
          this.exportLoading = false
        })
        exportSuccess()
      } else {
        const { platform, site, mulitSku, orderType, wmsOrderNumber, orderNumber, merchant, sourceOrderNumber } = this.form
        const {
          warehouseCodesStr,
          orderNumbersStr,
          wmsOrderNumbersStr,
          itemIdsStr,
          trackingNumber,
          buyerName,
          buyerEmail,
          style,
          orderPriceStart,
          orderPriceEnd,
          countryCode,
          city,
          stateorregion,
          createDateStart,
          createDateEnd,
          paymentsDateStart,
          paymentsDateEnd,
          orderCreateDateStart,
          orderCreateDateEnd,
          sendDateStart,
          sendDateEnd
        } = this.filterForm
        // 将上面url的参数转为对象，写死
        const obj = {
          platform,
          site,
          mulitSku,
          status: Number(this.status),
          orderType,
          orderNumber,
          merchant,
          wmsOrderNumber,
          orderNumbersStr,
          wmsOrderNumbersStr,
          itemIdsStr,
          trackingNumber,
          buyerName,
          buyerEmail,
          style,
          orderPriceStart,
          orderPriceEnd,
          countryCode,
          city,
          stateorregion,
          createDateStart,
          createDateEnd,
          paymentsDateStart,
          paymentsDateEnd,
          orderCreateDateStart,
          orderCreateDateEnd,
          sendDateStart,
          sendDateEnd,
          warehouseCodesStr: warehouseCodesStr.toString(),
          sourceOrderNumber,
          ...this.getDynamicParams
        }
        await unifiedExport({
          appName: 'oms',
          exportType: 'self-delivery-order',
          reqParam: JSON.stringify(obj)
        }).finally(() => {
          this.exportLoading = false
        })
        exportSuccess()
      }
    },
    // 手动分配仓库
    handSplitWarehouse(row) {
      const data = {}
      data.id = row.id
      data.site = row.site
      data.platform = row.platform

      this._handSplitWarehouse(data)
    },
    async _handSplitWarehouse(data) {
      const { code, error, msg } = await handSplitWarehouse(data)
      if (code === 0) {
        this.$notify({
          title: msg,
          message: msg,
          type: 'success'
        })
      } else {
        this.$notify({
          title: msg,
          message: error,
          type: 'success'
        })
      }
      this._listOmsSelfDeliveryOrderByPage(this.queryParameter)
    },

    // 转FBA发货
    handleFBA() {
      this.data = []
      this.sidvoList.map((e) => {
        this.data.push({
          orderNumber: e.orderNumber,
          site: e.site,
          platform: e.platform,
          operateFrom: 0
        })
      })
    },
    async _bleadToFbaForOmsOrder(data) {
      const { code, error, msg } = await bleadToFbaForOmsOrder(data)
      if (code === 0) {
        this.$notify({
          title: msg,
          message: msg,
          type: 'success'
        })
      } else {
        this.$notify({
          title: msg,
          message: error,
          type: 'success'
        })
      }
      this._listOmsSelfDeliveryOrderByPage(this.queryParameter)
    },
    handleBatchTransit() {
      if (this.sidvoList.length === 0) {
        this.$message.error(this.$t('title.select'))
        return
      }
      if (this.sidvoList.length > 500) {
        this.$message.error('批量操作最多500条')
        return
      }
      let valid = true
      const v2 = new Set()
      this.sidvoList.forEach((item) => {
        const r1 = +item.status === 8
        if (!r1) {
          v2.add(item.orderNumber)
          valid = false
        }
        const r2 = item.itemSplitList.every(row => (+row.status !== 6))
        if (r2) {
          v2.add(item.orderNumber)
          valid = false
        }
      })
      let msg = this.$t('title.batchprocessing')
      if (!valid) {
        msg = `所选订单中${Array.from(v2).join(',')}不是"库存不足"或发货商品状态不是"缺货"状态，无法转待调，是否跳过继续处理剩余订单？`
      }
      this.$confirm(msg, this.$t('page.Prompt'), {
        confirmButtonText: this.$t('title.confirm'),
        cancelButtonText: this.$t('title.cancel'),
        closeOnClickModal: false,
        customClass: 'error-tip',
        type: 'warning',
        beforeClose: async(action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = instance.cancelButtonLoading = true
            const orderNumberList = this.sidvoList.filter(item => {
              const v1 = +item.status === 8
              const v2 = item.itemSplitList.some(row => (+row.status === 6))
              return v1 && v2
            }).map(e => e.orderNumber)
            if (orderNumberList.length === 0) {
              this.$message.error(this.$t('title.select'))
              instance.confirmButtonLoading = instance.cancelButtonLoading = false
              done()
              return
            }
            const { code, error, msg } = await batchTransit({ orderNumberList }).finally(() => {
              instance.confirmButtonLoading = instance.cancelButtonLoading = false
            })
            if (code === 0) {
              this.$message.success(msg)
              done()
              this._listOmsSelfDeliveryOrderByPage(this.queryParameter)
            } else {
              this.$message.error(error)
            }
          } else {
            done()
          }
        }
      }).catch(() => {
      })
    },
    // 批量作废
    Batchvoid() {
      if (this.sidvoList.length === 0) {
        this.$message.error(this.$t('title.select'))
        return
      }
      this.$confirm(this.$t('title.cancelorder'), this.$t('page.Prompt'), {
        confirmButtonText: this.$t('title.confirm'),
        cancelButtonText: this.$t('title.cancel'),
        type: 'warning'
      }).then(() => {
        this._cancelorders(this.sidvoList)
      })
    },

    async _cancelorders(data) {
      this.tableLoading = true
      const { code, error, msg } = await cancelorders(data)
      if (code === 0) {
        this.$notify({
          title: msg,
          message: msg,
          type: 'success'
        })
        this.tableLoading = true
      } else {
        this.$notify({
          title: msg,
          message: error,
          type: 'success'
        })
      }
      this._listOmsSelfDeliveryOrderByPage(this.queryParameter)
    },
    // 批量分配(库存不足/待分配)
    Batchallocation() {
      this.arr = []
      if (this.sidvoList.length === 0) {
        this.$message.error(this.$t('title.select'))
        return
      }
      //
      var flag = this.sidvoList.every(item =>
        item.itemSplitList.find(i => i.status === 2)
      )
      this.sidvoList.map((item) => {
        console.log(item.itemSplitList.find((i) => i.status === 2))
        if (!item.itemSplitList.find((i) => i.status === 2)) {
          this.arr.push(item.orderNumber)
          if (this.arr.length) {
            this.alloatedialogVisible = true
          }
        } else if (flag) {
          this.alloatedialogVisibleLoading = true
        }
      })
    },
    alloatedialogVisibleSure() {
      // 都符合分仓条件
      // this.$confirm(
      // this.$t('title.allocateorders'),
      // this.$t('page.Prompt'),
      // {
      //   confirmButtonText: this.$t('title.confirm'),
      //   cancelButtonText: this.$t('title.cancel'),
      //   type: 'warning'
      // }
      // ).then(() => {
      if (this.sidvoList) {
        // const orderarr = []
        // this.sidvoList.map((item) => {
        //   if (!item.buyerPhone) {
        //     orderarr.push(item.orderNumber)
        //   }
        // })
        // if (this.sidvoList.filter((item) => Boolean(item.buyerPhone)).length > 0) {
        // this.sidvoList = this.sidvoList.filter(
        //   (item) => Boolean(item.buyerPhone)
        // )
        this.sidvoList.map((item) => {
          item.itemSplitList = item.itemSplitList.filter(
            (i) => i.status === 2
          )
          item.itemSplitListAllocation = item.itemSplitList
          return { ...item }
        })
        this._allocationorders(this.sidvoList)
        // } else {
        //   this.$notify({
        //     title: '操作成功',
        //     message: '操作成功',
        //     type: 'success'
        //   })
        //   this._listOmsSelfDeliveryOrderByPage(this.queryParameter)
        // }
        // this.$confirm(
        //   orderarr + this.$t('title.Noorder'),
        //   this.$t('page.Prompt'),
        //   {
        //     confirmButtonText: this.$t('title.confirm'),
        //     cancelButtonText: this.$t('title.cancel'),
        //     type: 'warning'
        //   }
        // ).then(() => {
        //   if (this.sidvoList.filter((item) => Boolean(item.buyerPhone)).length > 0) {
        //     // this.sidvoList = this.sidvoList.filter(
        //     //   (item) => Boolean(item.buyerPhone)
        //     // )
        //     this.sidvoList.map((item) => {
        //       item.itemSplitList = item.itemSplitList.filter(
        //         (i) => i.status === 2
        //       )
        //       item.itemSplitListAllocation = item.itemSplitList
        //       return { ...item }
        //     })
        //     console.log(this.sidvoList)
        //     this._allocationorders(this.sidvoList)
        //   } else {
        //     this.$notify({
        //       title: '操作成功',
        //       message: '操作成功',
        //       type: 'success'
        //     })
        //     this._listOmsSelfDeliveryOrderByPage(this.queryParameter)
        //   }
        // })
      } else {
        this.sidvoList.map((item) => {
          item.itemSplitList = item.itemSplitList.filter(
            (i) => i.status === 2
          )
          item.itemSplitListAllocation = item.itemSplitList
          return { ...item }
        })
        console.log(this.sidvoList)
        this._allocationorders(this.sidvoList)
        this.alloatedialogVisibleLoading = false
      }
    },
    alloateVisibleSure() {
      this.arr.forEach(item => {
        this.sidvoList = this.sidvoList.filter(i => i.orderNumber !== item)
      })
      if (this.sidvoList) {
        // const orderarr = []
        // this.sidvoList.map((item) => {
        //   if (!item.buyerPhone) {
        //     orderarr.push(item.orderNumber)
        //   }
        // })
        // if (this.sidvoList.filter((item) => Boolean(item.buyerPhone)).length > 0) {
        // this.sidvoList = this.sidvoList.filter(
        //   (item) => item.buyerPhone
        // )
        this.sidvoList.map((item) => {
          item.itemSplitList = item.itemSplitList.filter(
            (i) => i.status === 2
          )
          item.itemSplitListAllocation = item.itemSplitList
          return { ...item }
        })
        this._allocationorders(this.sidvoList)
        // } else {
        //   this.$notify({
        //     title: '操作成功',
        //     message: '操作成功',
        //     type: 'success'
        //   })
        //   this._listOmsSelfDeliveryOrderByPage(this.queryParameter)
        // }
        // this.$confirm(
        //   orderarr + this.$t('title.Noorder'),
        //   this.$t('page.Prompt'),
        //   {
        //     confirmButtonText: this.$t('title.confirm'),
        //     cancelButtonText: this.$t('title.cancel'),
        //     type: 'warning'
        //   }
        // ).then(() => {
        //   if (this.sidvoList.filter((item) => Boolean(item.buyerPhone)).length > 0) {
        //     // this.sidvoList = this.sidvoList.filter(
        //     //   (item) => item.buyerPhone
        //     // )
        //     this.sidvoList.map((item) => {
        //       item.itemSplitList = item.itemSplitList.filter(
        //         (i) => i.status === 2
        //       )
        //       item.itemSplitListAllocation = item.itemSplitList
        //       return { ...item }
        //     })
        //     this._allocationorders(this.sidvoList)
        //   } else {
        //     this.$notify({
        //       title: '操作成功',
        //       message: '操作成功',
        //       type: 'success'
        //     })
        //     this._listOmsSelfDeliveryOrderByPage(this.queryParameter)
        //   }
        // })
      } else {
        this.sidvoList.map((item) => {
          item.itemSplitList = item.itemSplitList.filter((i) => i.status === 2)
          item.itemSplitListAllocation = item.itemSplitList
          return { ...item }
        })
        console.log(this.sidvoList)
        this._allocationorders(this.sidvoList)
      }
      this.alloatedialogVisible = false
    },
    async _allocationorders(data) {
      try {
        this.alloateSureLoading = true
        const { code, error, msg } = await allocationorders(data)
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          this._listOmsSelfDeliveryOrderByPage(this.queryParameter)
        } else {
          this.$notify({
            title: msg,
            message: error,
            type: 'success',
            duration: 10000
          })
          this._listOmsSelfDeliveryOrderByPage(this.queryParameter)
        }
        this.alloatedialogVisibleLoading = false
        this.alloatedialogVisible = false
      } catch (err) {
        this.alloateSureLoading = false
        this._listOmsSelfDeliveryOrderByPage(this.queryParameter)
      } finally {
        this.alloateSureLoading = false
        this.alloatedialogVisibleLoading = false
      }
    },
    // tab栏切换
    handleTabsClick(item) {
      this.allcount = 0
      console.log(item.status)
      if (item.status === 0 || item.status === 1 || item.status === 2 || item.status === 6 || item.status === 7 || item.status === 8 || item.status === 10 || item.status === 101 || item.status === 100 || item.status === 999) {
        this.isOrderAgin = true
        this.aginStatus = ''
      } else {
        this.isOrderAgin = false
      }
      this.status = item.status + ''
      this.pager.current = 1
      this._listOmsSelfDeliveryOrderByPage(this.queryParameter, 0, 5)
      let obj = {}
      obj = deepClone(this.queryStatusParameter)
      delete obj.descs
      this._listOmsSelfDeliveryOrderByPageStatusGroup(obj)
    },
    bbbb(row) {
      this.allcount = 0
      if (row === undefined) {
        this.isOrderAgin = false
        this.aginStatus = ''
      } else {
        this.isOrderAgin = true
      }
      this._listOmsSelfDeliveryOrderByPage(this.queryAllParameter, 0, 5)
      let obj = {}
      obj = deepClone(this.queryAllStatusParameter)
      delete obj.descs
      this._listOmsSelfDeliveryOrderByPageStatusGroup(obj)
    },
    aaaa(row) {
      this.allcount = 0
      if (row === undefined || row.length > 0) {
        this.isOrderAgin = true
        this.aginStatus = ''
      } else {
        this.isOrderAgin = false
      }
      this.status = ''
      this._listOmsSelfDeliveryOrderByPage(this.selectAllErrorParameter, 0, 5)
      let obj = {}
      obj = deepClone(this.queryErrorStatusParameter)
      delete obj.descs
      this._listOmsSelfDeliveryOrderByPageStatusGroup(obj)
    },
    selectStatus(item) {
      console.log(item)
      this.allcount = 0
      if (this.params.statusList && this.params.statusList.length === this.options.length) {
        if (item === '') {
          this.queryParameter.orderAging = ''
          //   this.selectAllErrorParameter.orderAging = ''
          this.aginStatus = ''
          this._listOmsSelfDeliveryOrderByPage(this.selectAllErrorParameter, 0, 5)
          let obj = {}
          obj = deepClone(this.selectAllErrorParameter)
          delete obj.descs
          delete obj.orderAging
          this._listOmsSelfDeliveryOrderByPageStatusGroup(obj)
        } else {
          this.aginStatus = item.status
          this.selectAllErrorParameter.orderAging = item.status
          this.selectAllErrorParameter.current = 1
          this._listOmsSelfDeliveryOrderByPage(this.selectAllErrorParameter, 0, 5)
          let obj = {}
          obj = deepClone(this.selectAllErrorParameter)
          delete obj.descs
          delete obj.orderAging
          this._listOmsSelfDeliveryOrderByPageStatusGroup(obj)
        }
      } else {
        if (item === '') {
          this.queryParameter.orderAging = ''
          //   this.selectAllErrorParameter.orderAging = ''
          this.aginStatus = ''
          this._listOmsSelfDeliveryOrderByPage(this.queryParameter)
        } else {
          this.aginStatus = item.status
          this.queryParameter.orderAging = item.status
          this.queryParameter.current = 1
          this._listOmsSelfDeliveryOrderByPage(this.queryParameter)
        }
      }

      // if (item === '') { // 全部
      //   this.queryParameter.orderAging = ''
      //   this.selectAllErrorParameter.orderAging = ''
      //   this.aginStatus = ''
      //   // this.queryAllParameter.current = 1
      //   this._listOmsSelfDeliveryOrderByPage(this.queryParameter)
      // } else { // 其他
      //   if (this.params.statusList && this.params.statusList.length === this.options.length) {
      //     this.selectAllErrorParameter.orderAging = item.status
      //     this.selectAllErrorParameter.current = 1
      //     this._listOmsSelfDeliveryOrderByPage(this.selectAllErrorParameter, 0, 5)
      //     let obj = {}
      //     obj = deepClone(this.selectAllErrorParameter)
      //     delete obj.descs
      //     delete obj.orderAging
      //     this._listOmsSelfDeliveryOrderByPageStatusGroup(obj)
      //   } else {
      //     this.queryParameter.orderAging = item.status
      //     this.queryParameter.current = 1
      //     console.log(this.queryParameter.orderAging)
      //     this._listOmsSelfDeliveryOrderByPage(this.queryParameter)
      //   }
      //   this.aginStatus = item.status
      // }
      // if (this.params.statusList && this.params.statusList.length === this.options.length) {
      //   this.selectAllErrorParameter.current = 1
      //   this._listOmsSelfDeliveryOrderByPage(this.selectAllErrorParameter, 0, 5)
      //   let obj = {}
      //   obj = deepClone(this.selectAllErrorParameter)
      //   delete obj.descs
      //   this._listOmsSelfDeliveryOrderByPageStatusGroup(obj)
      // } else {
      //   this.queryParameter.current = 1
      //   console.log(this.queryParameter.orderAging)
      //   this._listOmsSelfDeliveryOrderByPage(this.queryParameter)
      // }
      // let obj = {}
      // obj = deepClone(this.queryStatusParameter)
      // delete obj.descs
      // this._listOmsSelfDeliveryOrderByPageStatusGroup(obj)
    },
    getMultiple(val) {
      this.sidvoList = val
    },
    handleTableSelectReset() {
      this.sidvoList = []
      this.$refs.table.$refs.table.clearSelection()
      /* for (var i = 0; i < this.$refs.table.length; i++) {
        this.$refs.table[i].$refs.table.clearSelection()
      } */
    },
    handleSizeChange(val) {
      this.pager.size = val
      this.allcount = 0
      const statusList = this.params.statusList || []
      if (statusList.length === this.options.length) {
        this.selectAllErrorParameter.current = 1
        this.selectAllErrorParameter.orderAging = this.aginStatus
        this._listOmsSelfDeliveryOrderByPage(this.selectAllErrorParameter, 0, 5)
        let obj = {}
        obj = deepClone(this.selectAllErrorParameter)
        delete obj.descs
        delete obj.orderAging
        this._listOmsSelfDeliveryOrderByPageStatusGroup(obj)
      } else if (statusList.length === this.statusList.length) {
        this.queryAllParameter.current = 1
        this.queryAllParameter.orderAging = this.aginStatus
        this._listOmsSelfDeliveryOrderByPage(this.queryAllParameter, 0, 5)
        let obj = {}
        obj = deepClone(this.queryAllParameter)
        delete obj.descs
        delete obj.orderAging
        this._listOmsSelfDeliveryOrderByPageStatusGroup(obj)
      } else {
        this.queryParameter.current = 1
        this.queryParameter.orderAging = this.aginStatus
        this._listOmsSelfDeliveryOrderByPage(this.queryParameter, 0, 5)
        let obj = {}
        obj = deepClone(this.queryParameter)
        delete obj.descs
        delete obj.orderAging
        this._listOmsSelfDeliveryOrderByPageStatusGroup(obj)
      }
    },
    handleCurrentChange(val) {
      this.pager.current = val
      this.allcount = 0
      const statusList = this.params.statusList || []
      if (statusList.length === this.options.length) {
        this.selectAllErrorParameter.orderAging = this.aginStatus
        this._listOmsSelfDeliveryOrderByPage(this.selectAllErrorParameter, 0, 5)
        let obj = {}
        obj = deepClone(this.selectAllErrorParameter)
        delete obj.descs
        delete obj.orderAging
        this._listOmsSelfDeliveryOrderByPageStatusGroup(obj)
      } else if (statusList.length === this.statusList.length) {
        this.queryAllParameter.orderAging = this.aginStatus
        this._listOmsSelfDeliveryOrderByPage(this.queryAllParameter, 0, 5)
        let obj = {}
        obj = deepClone(this.queryAllParameter)
        delete obj.descs
        delete obj.orderAging
        this._listOmsSelfDeliveryOrderByPageStatusGroup(obj)
      } else {
        this.queryParameter.orderAging = this.aginStatus
        this._listOmsSelfDeliveryOrderByPage(this.queryParameter, 0, 5)
        let obj = {}
        obj = deepClone(this.queryParameter)
        delete obj.descs
        delete obj.orderAging
        this._listOmsSelfDeliveryOrderByPageStatusGroup(obj)
      }
    },
    selectAllError() {
      this._listOmsSelfDeliveryOrderByPage(this.selectAllErrorParameter)
    },
    showScreen() {
      this.dialogFormVisiblefilter = true
      if (this.filterForm.warehouseCodesStr !== '' && !(this.filterForm.warehouseCodesStr instanceof Array)) {
        this.filterForm.warehouseCodesStr = this.filterForm.warehouseCodesStr.split(',')
      }
    }
  }
}
</script>
<style lang="scss" scope>
.row {
  position: relative;
}

.ul {
  list-style: none;
  position: absolute;
  z-index: 999;
  top: 0px;
  right: 0px;

  li {
    line-height: 20px;
  }
}

.el-table__expanded-cell[class*="cell"] {
  padding: 20px 35px 0 20px;
}

.expand-row {
  height: 44px;
  line-height: 44px;
  text-align: left;

  &.border {
    border-bottom: 1px solid #dfe6ec;
  }
}

.el-date-editor .el-range-separator {
  width: 24%;
}

.active {
  background-color: #1890ff;
  color: white;
}

.actives {
  background-color: #1890ff;
  color: white;
}

.el-date-editor .el-range-separator {
  width: 24%;
}

.count {
  color: red;
  margin-left: 10px;
}

.activeCount {
  color: pink !important;
}

.el-tabs {
  position: relative;

  ul {
    display: none;
    position: absolute;
    top: 0px;
    right: 263px;
    list-style: none;
    padding-left: 0;
    margin-top: 0px !important;
  }
}

.form .el-form-item__label {
  font-weight: 400;
}

.el-pagination {
  font-weight: 400;
}

.tab .el-input__inner {
  width: 140px;
}

.error-tip {

  p {
    overflow: auto;
  }
}
</style>
