<template>
  <el-dialog :before-close="handleClose" :visible.sync="visible" title="导入数据" top="5vh" width="1000px">
    <ElSteps :active="active" align-center class="mb-2">
      <ElStep title="上传文件" />
      <ElStep title="执行导入" />
      <ElStep title="导出数据" />
    </ElSteps>
    <template v-if="active === 1">
      <div style="display: flex; height: 7rem; border: 1px solid #e5e7eb;">
        <div style="width: 7rem;background-color: rgb(229 231 235 / 0.5);display: flex;align-items: center;justify-content: center">
          <i class="el-icon-edit" style="font-size: 48px" />
        </div>
        <div style="display: flex; flex: 1; flex-direction: column; justify-content: center; padding: 0.5rem;">
          <div style="font-weight: 700; padding-top: 0.25rem; padding-bottom: 0.25rem; font-size: 1rem;"> 填写订单信息 </div>
          <div style="color: rgba(156, 163, 175, 0.7); font-size: 0.875rem; padding-top: 0.25rem; padding-bottom: 0.25rem;">
            请按照数据模板的格式准备导入数据，模板中的表头名称不可更改，表头行不能删除
          </div>
          <div style="padding-top: 0.25rem; padding-bottom: 0.25rem;">
            <ElLink :href="importTemplateUrl" :underline="false" target="_blank" type="primary">
              下载模板
            </ElLink>
          </div>
        </div>
      </div>
      <div style="display: flex; height: 7rem; border: 1px solid #e5e7eb; margin-top: 1rem;">
        <div style="width: 7rem; background-color: rgba(229, 231, 235, 0.5); display: flex; align-items: center; justify-content: center;">
          <i class="el-icon-upload2" style="font-size: 48px" />
        </div>
        <div style="display: flex; flex: 1; flex-direction: column; justify-content: center; padding: 0.5rem;">
          <div style="font-weight: bold; padding-top: 0.25rem; padding-bottom: 0.25rem; font-size: 1rem;"> 上传填好的订单表 </div>
          <div style="color: rgba(156, 163, 175, 0.7); font-size: 0.875rem; padding-top: 0.25rem; padding-bottom: 0.25rem;">
            文件后缀名必须为xls或xlsx(即Excel格式),文件大小不不得大于10M,最多支持导入3000条数据
          </div>
          <div style="padding-top: 0.25rem; padding-bottom: 0.25rem;">
            <upload
              ref="upload"
              :http-request="handleUpload"
              :limit="1"
              :size-limit="10 * 1024 * 1024"
              accept=".xls,.xlsx"
              list-type="text"
              style="display: inline-block"
              @remove="handleRemove"
            >
              <ElLink :underline="false" type="primary"> 上传文件 </ElLink>
            </upload>
          </div>
        </div>
      </div>
      <div
        style="display: flex; height: 7rem; margin-top: 1rem; background-color: #fdf6ec; border: 1px solid #f5dab1; border-radius: 0.375rem;"
      >
        <div style="width: 7rem; display: flex; align-items: center; justify-content: center;">
          <i class="el-icon-warning-outline" style="font-size: 48px;color: #E6A23C" />
        </div>
        <div style="display: flex; flex: 1; flex-direction: column; justify-content: center; padding: 0.5rem;">
          <div style="font-weight: bold; padding-top: 0.25rem; padding-bottom: 0.25rem; font-size: 1rem;"> 特别提示 </div>
          <div style="color: rgba(156, 163, 175, 0.7); font-size: 0.875rem; padding-top: 0.25rem;">
            导入过程中如发现重复订单号，或订单号不存在，则忽略这条订单数据
          </div>
        </div>
      </div>
    </template>
    <template v-if="active === 2">
      <div v-if="submitLoading" style="height: 24rem; display: flex; flex-direction: column; align-items: center; justify-content: center;">
        <el-progress :color="colors" :percentage="percentage" :stroke-width="16" style="width: 50%;" />
        <span style="margin-top: 2rem;">正在导入数据</span>
      </div>
      <el-result
        v-else-if="isSuccess"
        :sub-title="`您已成功导入${tableData.length}条数据`"
        icon="success"
        title="批量导入完成"
      />
      <el-result v-else icon="error" sub-title="请检查数据后重新导入" title="批量导入失败" />
    </template>
    <template v-if="active === 3">
      <div style="display: flex; height: 7rem; border: 1px solid #e5e7eb;">
        <div style="width: 7rem; background-color: rgba(229, 231, 235, 0.5); display: flex; align-items: center; justify-content: center;">
          <i class="el-icon-check" style="font-size: 48px" />
        </div>
        <div style="display: flex; flex: 1; flex-direction: column; justify-content: center; padding: 0.5rem;">
          <div style="font-weight: 700; padding-top: 0.25rem; padding-bottom: 0.25rem; font-size: 1rem;"> 下载物流信息 </div>
          <div style="color: rgba(156, 163, 175, 0.7); font-size: 0.875rem; padding-top: 0.25rem; padding-bottom: 0.25rem;">
            请点击下方按钮，下载匹配成功的物流单号表
          </div>
          <div style="font-weight: bold; padding-top: 0.25rem; padding-bottom: 0.25rem;">
            <span
              style="font-weight: normal; color: #3b82f6; cursor: pointer;"
              @click="handleDownload"
            >
              下载物流单号表
            </span>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <ElButton @click="handleClose">取消</ElButton>
      <ElButton
        v-if="active === 1"
        :loading="submitLoading"
        type="primary"
        @click="handleImport"
      >
        下一步
      </ElButton>
    </template>
  </el-dialog>
</template>

<script>
import Upload from '@/components/Upload/index.vue'
import { exportTrackNo } from '@/api/omsorder'

export default {
  name: 'ExportTrackingNumberDialog',
  components: {
    Upload
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      active: 1,
      file: null,
      percentage: 0,
      importTemplateUrl: 'https://aliyun-oa-query-results-1509030252432744-oss-cn-hongkong.oss-cn-hongkong.aliyuncs.com/template/export_tracking_number.xlsx',
      downloadUrl: '',
      submitLoading: false,
      colors: [
        { color: '#f56c6c', percentage: 40 },
        { color: '#e6a23c', percentage: 80 },
        { color: '#5cb87a', percentage: 100 }
      ],
      isSuccess: false,
      tableData: []
    }
  },
  methods: {
    handleClose() {
      this.active = 1
      this.file = null
      this.percentage = 0
      this.$emit('update:visible', false)
    },
    handleDownload() {
      window.open(this.downloadUrl)
    },
    async handleImport() {
      if (!this.file) {
        this.$message.warning('请上传文件')
        return
      }
      this.active = 2
      this.submitLoading = true
      const formData = new FormData()
      formData.append('file', this.file)
      const { msg, datas } = await exportTrackNo(formData).catch(() => {
        this.isSuccess = false
      }).finally(() => {
        this.submitLoading = false
      })
      const timer = setInterval(() => {
        this.percentage = (this.percentage % 100) + 10
        if (this.percentage >= 100) {
          this.percentage = 99
        }
      }, 500)
      clearInterval(timer)
      this.downloadUrl = datas
      this.isSuccess = true
      this.$message.success(msg)
      this.active = 3
    },
    handleUpload({ file }) {
      this.file = file
    },
    handleRemove() {
      this.file = null
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
