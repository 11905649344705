export const Status_Enum = {
  0: 'To be allocated',
  1: 'Assigning',
  2: 'Assigned',
  3: 'Partial shipment',
  4: 'Shipped',
  5: 'Unable deliver',
  6: 'Part Of Distribution',
  7: 'Product Mismatch',
  8: 'Insufficient Inventory',
  9: 'Inventory to stay tuned',
  10: 'cannot get out warehouse',
  97: 'Abolished',
  98: 'Abolishing',
  99: 'Cancelleds',
  100: 'Handle TimeOut',
  101: 'Last Tracking Failed',
  102: 'Get trace failed',
  103: 'Abnormal Distribution',
  104: 'Incomplete customer information',
  201: 'To Audit',
  202: 'Not Approved',
  301: 'After sale service order',
  999: 'Order exception',
  105: 'Fraudulent order'

}

export const Status_Detail_Enum = {
  0: 'Normal aging',
  1: 'To be shipped today',
  2: 'Delivery timeout'
}

// 库存不足子分类
export const InsufficientInventoryOptions = [
  {
    label: '缺货',
    value: 0
  },
  {
    label: 'MCF创建失败',
    value: 1
  },
  {
    label: 'FBA临期未发货',
    value: 2
  }
]
